<template>
    <div class="order" @scroll="handleScroll">
        <div class="order-search">
            <el-input
                clearable
                placeholder="订单搜索,点击键盘enter搜素"
                prefix-icon="el-icon-search"
                v-model="keywords"
                @change="search"
            >
            </el-input>
            <el-tooltip content="刷新" placement="top">
                <span class="el-icon-refresh" @click="handleReloadIcon"></span>
            </el-tooltip>
        </div>
        <el-empty
            style="margin-top: 50px"
            description="暂无订单"
            v-if="List.length == 0"
        ></el-empty>
        <div class="order-box" ref="scrollDiv" v-else>
            <ul class="order-list">
                <li
                    v-for="item in List"
                    @click="details(item.orderId)"
                    :key="item.orderId"
                >
                    <div class="title">
                        <p class="">订单号:{{ item.orderId }}</p>
                        <span>{{ ORDER_STATUS[`${item.orderState}`] }}</span>
                    </div>
                    <div class="order-info">
                        <img :src="item.coverUrl" alt="" />
                        <div style="margin-left: 8px">
                            <tableText
                                :contentConfig="{
                                    text: item.goodsName,
                                }"
                            />
                            <tableText
                                :contentConfig="{
                                    text: item.meal,
                                    max: 2,
                                    style: { color: '#6f7070' },
                                }"
                            />
                        </div>
                        <div class="price">
                            <span class="money">{{
                                amountInYuan(item.totalPrice)
                            }}</span>

                            <span class="num">共{{ item.num }}件</span>
                        </div>
                    </div>
                    <span class="time">下单时间 {{ item.createTime }}</span>
                    <!-- <el-statistic
                        v-if="item.orderState == 'NO_PAID'"
                        ref="statistic"
                        format="mm分ss秒"
                        title="订单将在："
                        :value-style="valueStyle"
                        :value="time(item.second)"
                        time-indices
                    >
                        <template slot="suffix"> 取消 </template>
                    </el-statistic> -->
                    <div class="btn">
                        <span
                            v-hasPermi="['goods:order:up_shipping_addr']"
                            v-if="
                                item.orderState === 'NO_PAID' ||
                                item.orderState === 'WAIT_SHOP'
                            "
                            @click.stop="toOrder(item)"
                            >修改地址</span
                        >
                        <span
                            v-if="item.orderState == 'WAIT_SHOP'"
                            v-hasPermi="['goods:order:deliver']"
                            @click.stop="toOrder(item)"
                            >去发货</span
                        >
                        <span
                            v-if="
                                item.orderState == 'WAIT_SHOP' ||
                                item.orderState == 'WAIT_SIGN' ||
                                item.orderState == 'SUCCESS'
                            "
                            v-hasPermi="['goods:order:refund']"
                            @click.stop="toOrder(item)"
                        >
                            去退款
                        </span>
                        <span
                            v-hasPermi="['goods:order:up_deliver']"
                            @click.stop="toOrder(item)"
                            v-if="item.orderState == 'WAIT_SIGN'"
                            >修改物流</span
                        >
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
import { ORDER_STATUS } from "@/utils/constant.js";
import { orderList } from "@/api/order.js";
import tableText from "@/component/tableText";
export default {
    components: { tableText },
    props: {
        userId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ORDER_STATUS,
            pageNum: 1,
            List: [], //订单列表
            keywords: "",
            valueStyle: {
                color: " #fd3d2d",
                fontSize: "14px",
            },
            deadline4: "2023-12-04 15:05:29",
        };
    },
    watch: {
        // 监听userI变化
        userId(newValue) {
            if (newValue) {
                this.search();
            } else {
                this.List = [];
                this.pageNum = 1;
            }
        },
    },
    computed: {
        amountInYuan() {
            return (val) => {
                if (val) {
                    return `￥${(val / 100).toFixed(2)}`;
                } else {
                    return;
                }
            };
        },
    },
    methods: {
        ...mapMutations(["setCustomer"]),
        toOrder(item) {
            this.setCustomer();
            this.$router.push(`/shopManagement/order?orderId=${item?.orderId}`);
        },
        handleReloadIcon() {
            if (!this.userId) return;
            this.List = [];
            this.keywords = "";
            this.pageNum = 1;
            this.getList();
        },
        // 触底事件
        handleScroll(e) {
            if (
                e.srcElement.scrollTop + e.srcElement.clientHeight + 1 >
                e.srcElement.scrollHeight
            ) {
                if (this.pageNum == 0) return;
                this.pageNum++;
                this.getList();
            }
        },
        // 搜索
        search() {
            if (!this.userId) return;
            this.List = [];
            this.pageNum = 1;
            this.getList();
        },
        // 获取订单列表
        async getList() {
            let params = {
                orderId: this.keywords,
                pageNum: this.pageNum,
                pageSize: 10,
                isAsc: "desc",
                orderByColumn: "orderId",
                userId: this.userId,
            };
            const res = await orderList(params);
            if (res.code == 200) {
                if (this.keywords && res.data.total == 0) {
                    this.$message.info("无搜索记录");
                }
                this.isOrder = true;
                this.List = this.List.concat(res.data.records || []);
                if (this.List.length == res.data.total) {
                    // 没有更多了
                    this.pageNum = 0;
                }
            }
        },
        // 把秒转成时间戳
        time(s) {
            let m = Number(s);
            const currentTimestamp = Math.floor(new Date().getTime() / 1000);
            return (currentTimestamp + m) * 1000;
        },
        // 详情
        details(id) {
            this.$emit("toDetail", id);
        },
    },
};
</script>
<style lang="scss" scoped>
.order {
    width: 375px;
    height: 544px;
    background: #fff;
    overflow: auto;
}
.order-search {
    display: flex;
    align-items: center;
    justify-content: center;
}

::v-deep .el-input--suffix .el-input__inner {
    width: 100%;
    height: 40px;
}
.el-icon-refresh {
    font-size: 18px;
    color: #409eff;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 20px;
}
.el-input {
    flex: 1;
    margin-left: 10px;
}
.order-list {
    background: #f9f9f9;
    li {
        box-sizing: border-box;
        margin-bottom: 5px;
        padding: 10px;
        width: 100%;
        background: #ffffff;
        font-size: 14px;
        .btn {
            display: flex;
            justify-content: flex-end;
            span {
                display: block;
                width: 83px;
                height: 30px;
                background: #f9f9f9;
                border-radius: 17px;
                margin-left: 10px;
                line-height: 30px;
                text-align: center;
                cursor: pointer;
            }
        }

        .tips {
            color: #fd3d2d;
            display: flex;
        }
        .order-info {
            height: 80px;
            background: #f9f9f9;
            border-radius: 8px;
            margin-top: 6px;
            display: flex;
            padding: 8px 10px;
            font-size: 14px;
            box-sizing: border-box;
            margin-bottom: 10px;
            > img {
                width: 64px;
                height: 64px;
                border-radius: 2px;
            }
            p {
                color: #000000;
                margin-left: 10px;
                width: 160px;
            }
            .price {
                margin-left: auto;
                .money {
                    color: #000000;
                    display: block;
                    margin-bottom: 8px;
                }
                .num {
                    color: #b4b4b4;
                }
            }
        }
        .title {
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            p {
                color: #000000;
                width: 230px;
            }
            span {
                color: #fd3d2d;
            }
        }
        .time {
            font-size: 14px;
            color: #6f7070;
            display: block;
            margin-bottom: 10px;
        }
    }
}
.el-statistic {
    display: flex;
    font-size: 14px;
}
::v-deep .el-statistic .head {
    font-size: 14px;
    color: #fd3d2d;
}
::v-deep .el-statistic .con {
    color: #fd3d2d;
}
</style>
