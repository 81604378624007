export const SHOP_ITEM_EL_TYPE = {
    SHOP_BASE_INFO: 1,
    ADD_SPEC_INFO: 2,
    ADD_PAGE_COMPONENT: 3,
};

/** 组件类型 */
export const COMPONENT_TYPE = {
    BANNER: "轮播图",
    SECOND_PIC: "二级图片",
    DIRECTORDERING: "直达下单",
    PIC_ARR: "图片组",
};

/* 售后状态枚举 */
export const SALE_TYPE = {
    WAIT_AUDIT: "待审核",
    WAIT_BACK: "待寄回",
    REFUND_CENTER: "退款中",
    OK_REFUND: "已退款",
    REFUSE: "已拒绝",
    CLOSE: "已取消",
};

/** 订单状态枚举 */
export const ORDER_STATUS = {
    UNKNOWN: "未知",
    NO_PAID: "待支付",
    CLOSE: "已关闭",
    WAIT_SHOP: "待发货",
    WAIT_SIGN: "待收货",
    AFTER_SALE: "售后中",
    OK_REFUND: "已退款",
    SUCCESS: "交易完成",
};
// 小程序列表审核状态
export const MINI_STATUS = {
    0: "审核通过",
    1: "驳回",
    2: "审核中",
    3: "待审核",
};
// 微信小程序投诉单问题类型枚举
export const WXAPPTYPE_STATUS = {
    CODE_611: "发货问题-未按约定时间发货",
    CODE_612: "发货问题-商家拒绝发货",
    CODE_613: "发货问题-少发/错发",
    CODE_614: "发货问题-物流信息长时间不更新",
    CODE_621: "客服问题-客服不回复",
    CODE_622: "客服问题-客服辱骂/骚扰/恐吓",
    CODE_631: "承诺未履行-赠品承诺未履行",
    CODE_632: "承诺未履行-物流承诺未履行",
    CODE_633: "承诺未履行-其他",
    CODE_641: "商品问题-描述不符",
    CODE_642: "商品问题-商品破损",
    CODE_643: "商品问题-其他",
    CODE_650: "收费异常问题-车辆没电或故障",
    CODE_651: "收费异常问题-关锁成功仍计费",
    CODE_652: "收费异常问题-扣费金额不对",
    CODE_653: "收费异常问题-规范停车仍扣调度费",
    CODE_654: "收费异常问题-其他",
    CODE_655: "骑行卡问题-已购买骑行卡，仍扣费",
    CODE_656: "骑行卡问题-骑行卡退款",
    CODE_657: "押金/余额退还问题-押金退还",
    CODE_658: "押金/余额退还问题-余额退还",
    CODE_659: "客服问题-客服不回复",
    CODE_660: "收费异常问题-充电宝未弹出，已扣费",
    CODE_661: "收费异常问题-充电宝无法正常使用",
    CODE_662: "收费异常问题-已归还，仍计费",
    CODE_663: "收费异常问题-不认可计费时长",
    CODE_664: "收费异常问题-其他",
    CODE_665: "押金退还问题-押金退还异常",
    CODE_666: "其他问题-客服不回复",
    CODE_667: "其他问题-归还点少无法归还",
    CODE_668: "其他问题-误操作购买，需消费",
    CODE_670: "欺诈问题-虚假广告",
    CODE_671: "欺诈问题-剧集数量与承诺不符",
    CODE_672: "欺诈问题-付款金额与页面不符",
    CODE_673: "欺诈问题-剧集内容与宣传不符",
    CODE_674: "欺诈问题-营销活动问题",
    CODE_675: "扣费问题-重复扣费",
    CODE_676: "扣费问题-无理由扣费",
    CODE_677: "扣费问题-扣费标准不一致",
    CODE_678: "扣费问题-未成年误付款",
    CODE_679: "其他问题",
};
// 微信小程序投诉单状态枚举
export const WXAPP_STATUS = {
    CODE_101: "平台客服处理中",
    CODE_102: "用户取消申请",
    CODE_103: "平台客服处理中",
    CODE_104: "平台客服处理中",
    CODE_105: "平台客服处理中",
    CODE_106: "待商家补充凭证",
    CODE_107: "平台客服处理中",
    CODE_108: "待双方补充凭证",
    CODE_109: "平台客服处理中",
    CODE_112: "投诉已完结",
    CODE_115: "投诉已完结",
    CODE_116: "投诉已完结",
    CODE_201: "待处理",
    CODE_202: "商家超时未回应，待用户确认",
    CODE_203: "已回应,待用户确认",
    CODE_204: "已回应,待用户确认",
    CODE_205: "投诉已完结",
    CODE_206: "平台已判定为商责，待上传处理凭证",
    CODE_207: "平台客服核实凭证中",
    CODE_208: "超时未上传凭证",
    CODE_209: "投诉已关闭",
    CODE_305: "平台客服处理中",
    CODE_307: "平台客服处理中",
    CODE_308: "平台已判定为商责，待用户退货中",
    CODE_309: "平台已判定为商责，待用户退货中",
    CODE_310: "平台客服处理中",
    CODE_311: "签收异常",
};
// 投诉每个节点的类型;
export const itemType = {
    1: "用户发起投诉",
    2: "用户补充留言",
    3: "商家补充留言",
    7: "用户补充凭证",
    8: "商家补充凭证",
    11: "用户申请平台客服协助",
    12: "用户撤销投诉",
    13: "平台客服处理中",
    14: "待用户补充凭证",
    16: "待商家补充凭证",
    18: "平台要求双方补充凭证",
    26: "平台核实处理凭证异常，投诉关闭，请商家自行联系用户解决问题，保障用户体验",
    30: "平台已核实此投诉非商家责任，投诉已完结",
    31: "平台已核实此投诉为商家责任，待上传处理凭证（blameResult为0）/平台已核实此投诉为商家责任，待用户退货中（blameResult为1）",
    32: "平台已核实此投诉为商家责任，待上传处理凭证（blameResult为0）/平台已核实此投诉为商家责任，待用户退货中（blameResult为1）",
    33: "平台已核实此投诉非商家责任，投诉已完结",
    36: "平台已核实处理凭证，投诉完结",
    37: "平台核实处理凭证异常，投诉关闭，请商家自行联系用户解决问题，保障用户体验",
    101: "商家超时未回应投诉",
    104: "用户认可处理结果，投诉已完结",
    107: "商家超时未提交投诉处理凭证，平台客服处理中",
    108: "用户超时未确认商家回应结果，投诉已完结",
    109: "商家已回应投诉",
    110: "商家提交投诉处理凭证",
    111: "用户补充凭证超时",
    112: "商家补充凭证超时",
    113: "双方补充凭证超时",
};
// 支付投诉类型
export const PREBIEMTYPE = {
    REFUND: "申请退款",
    SERVICE_NOT_WORK: "服务权益未生效",
    OTHERS: "其他类型",
};
// 投诉单状态
export const complaintState = {
    PENDING: "待处理",
    PROCESSING: "处理中",
    PROCESSED: "已处理完成",
};
/** 有退款按钮的枚举 待发货和待收货商家可以主动退款 */
export const allowRefundArr = ["WAIT_SHOP", "WAIT_SIGN"];

export const CONSOLE_TYPE = {
    "ALL": "all",
    "PAGE": "page",
    "REQUEST": "request",
}

export const COMPLAIN_STATUS = {
    'PENDING': {
        effect: 'plain',
        type: 'danger',
        label: '待处理'
    },
    'PROCESSING': {
        effect: 'plain',
        type: 'warning',
        label: '处理中'
    },
    'PROCESSED': {
        effect: 'plain',
        type: 'success',
        label: '已完成'
    },
}