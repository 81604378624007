<template>
    <div class="content_right">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
                v-for="item in tabList"
                :key="item.id"
                :label="item.label"
                :name="item.name"
            >
                <div class="order" v-show="item.name === SINGLE_MERCHANT">
                    <transition name="el-fade-in-linear">
                        <orderList
                            :userId="userId"
                            v-show="isList"
                            @toDetail="toDetail"
                        />
                    </transition>
                    <transition name="el-fade-in">
                        <orderDetails
                            v-show="!isList"
                            :orderId="orderId"
                            @toBack="toBack"
                        />
                    </transition>
                </div>
                <div class="shortcut" v-show="item.name === MERCHANT_GROUP">
                    <shortcut @changeTextAreValue="handleChangeTextAreValue" />
                </div>
                <div
                    class="productCard tabs-item"
                    v-show="item.name === PRODUCT_CARD"
                >
                    <productCard @sendMsg="sendMsg" />
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import orderList from "./components/orderList.vue";
import orderDetails from "./components/orderDetails.vue";
import shortcut from "./components/shortcut.vue";
import productCard from "./components/productCard.vue";
import { mapState } from "vuex";
export default {
    props: {
        userId: {
            type: String,
            default: "",
        },
        changeTextAreValue: {
            type: Function,
        },
        sendsMsg: {
            type: Function,
        },
    },
    watch: {
        // 监听userI变化
        userId(newValue) {
            this.isList = true;
        },
    },
    components: {
        orderList,
        orderDetails,
        shortcut,
        productCard,
    },
    computed: {
        ...mapState(["isWS"]),
    },
    data() {
        return {
            isList: true,
            orderId: "",
            activeName: "first",
            SINGLE_MERCHANT: "first",
            MERCHANT_GROUP: "second",
            PRODUCT_CARD: "productCard",
            tabList: [
                {
                    id: 1,
                    label: "订单号",
                    name: "first",
                },
                {
                    id: 2,
                    label: "快捷语",
                    name: "second",
                },
                {
                    id: 3,
                    label: "商品卡片",
                    name: "productCard",
                },
            ],
        };
    },
    methods: {
        toBack() {
            this.isList = true;
        },

        handleChangeTextAreValue(value) {
            this.$emit("changeTextAreValue", value);
        },

        sendMsg(obj) {
            this.$emit("sendsMsg", obj);
        },

        toDetail(e) {
            this.$tool.isBetaConsole(e);
            this.orderId = e;
            this.isList = false;
        },
        handleClick(tab, event) {
            this.activeName = tab.name;
        },
    },
};
</script>
<style lang="scss" scoped>
.content_right {
    min-width: 375px;
    padding: 0 10px;
}
.shortcut,
.tabs-item {
    width: 375px;
}
</style>
