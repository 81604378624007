export const FROM_RULES = {
    appId: [
        {
            required: true,
            message: "请选择小程序",
            trigger: "blur",
        },
    ],
    channel: [
        {
            required: true,
            message: "请选择投放渠道",
            trigger: "blur",
        },
    ],
    pageType: [
        {
            required: true,
            message: "请选择落地页类型",
            trigger: "blur",
        },
    ],
    eventTypeUc: [
        {
            required: true,
            message: "请选择回传类型",
            trigger: "blur",
        },
    ],
    eventType: [
        {
            required: true,
            message: "请选择回传类型",
            trigger: "blur",
        },
    ],
    eventTypeBd: [
        {
            required: true,
            message: "请选择回传类型",
            trigger: "blur",
        },
    ],
    checkPath: [
        {
            required: true,
            message: "请输入审核地址",
            trigger: "blur",
        },
    ],
    softTextUrl: [
        {
            required: true,
            message: "请输入审核地址",
            trigger: "blur",
        },
    ],
    bdToken: [
        {
            required: true,
            message: "请输入百度Token",
            trigger: "blur",
        },
    ],
    goods: [
        {
            required: true,
            message: "请输入商品ID",
            trigger: "blur",
        },
    ],
    launchNote: [
        { required: true, message: "请输入备注", trigger: "blur" },
    ],
    rollbackRatio: [
        {
            required: true,
            message: "请输入回传比例",
        },
        {
            validator: (rule, value, callback) => {
                let reg = /^(1|([1-9]\d{0,1})|100)$/;
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error("请输入1~100正整数"));
                }
            },
        },
    ],
}

export const PAGE_TYPE = {
    /** 自研 */
    SELF_RESEARCH: {
        label: '自研',
        value: "SELF_RESEARCH"
    },
    /** 橙子 */
    ORANGE: {
        label: '橙子建站',
        value: "ORANGE"
    }
}

export const DEFAULT_FROM_DATA = {
    channelParam: {
        pageName: "",
        floorImg: "",
        floorName: "",
        btnImg: "",
        btnName: "",
        skip: false,
        btnStyle: "",
    },
    closeSkip: false,
    bdToken: '',
    eventType: "",
    appId: "",
    channel: "",
    checkPath: "",
    goods: "",
    clientType: "WX", //UNKNOWN
    goodsId: "",
    launchNote: "",
    rollbackRatio: "100",
    softText: false,
    eventTypeUc: "",
    softTextUrl: "",
    eventTypeBd: '',
    pddGoodsId: '',
    pddLaunchId: "",
    pageType: PAGE_TYPE.ORANGE.value,
    // checkPathUC: "",
}

export const SOFT_TEXT_SHOW = ["BD", "UC"];

export const LIST_TYPE = {
    PDD: 'pdd',
    SHOP: 'shop'
}
