<template>
    <div class="emoji-box">
        <ul class="emjioBox">
            <li
                v-for="item in Object.keys(emoji)"
                :key="item"
                @click="selectEmit(item)"
            >
                <a
                    v-if="emoji[item] && emoji[item].position"
                    :style="emojiStyle(item)"
                ></a>
                <a v-else-if="emoji[item].length < 5">{{ emoji[item] }}</a>
                <img v-else :src="emoji[item]" />
            </li>
        </ul>
    </div>
</template>
<script>
import emoji from "./emoji.json";
export default {
    data() {
        return {
            emoji,
        };
    },
    methods: {
        selectEmit(type) {
            // this.$tool.isBetaConsole(type, "type");
            this.$emit("emoji", type);
            // this.$refs.popover.doClose();
        },
        emojiStyle(item) {
            const emojiitem = this.emoji[item];
            if (!emojiitem) return {};
            return {
                display: "inline-block",
                background: `url('https://oss.mbhad.com/20231205/923c9c9625c7432a86cc1014640a7e42.png')  no-repeat`,
                width: `28px`,
                height: `28px`,
                "background-position": emojiitem.position,
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.emoji-box {
    position: absolute;
    width: 320px;
    height: 170px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    z-index: 9;
    bottom: 143px;
}
.emjioBox {
    background: #fff;
    height: 150px;
    width: 300px;
    overflow: auto;
    text-align: left;
    padding: 0;

    li {
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
    }
}
</style>
