<template>
    <div class="mbh-page">
        <div class="seize-a-seat"></div>
        <div class="mbh-sidebar">
            <Sidebar />
        </div>
        <main class="mbh-main">
            <header class="mbh-header">
                <Navbar />
            </header>
            <el-scrollbar class="mbh-content" ref="main_box">
                <div class="mask" v-show="customerShow"></div>
                <Customer v-show="customerShow" />
                <app-main @scrollToTop="scrollToTop" />
            </el-scrollbar>
        </main>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Customer from "@/component/customer";
import { userInfo } from "@/api/common.js";
import { Sidebar, Navbar, AppMain } from "./components";
import watermark from "@/utils/watermark";
export default {
    name: "Layout",
    components: {
        Sidebar,
        Navbar,
        Customer,
        AppMain,
    },
    mounted() {
        this.setWatermark();
    },
    destroyed() {
        watermark.remove();
    },
    computed: {
        ...mapState(["customerShow"]),
    },
    methods: {
        scrollToTop() {
            this.$refs.main_box.wrap.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        setWatermark() {
            userInfo().then((res) => {
                const { tenantName } = res.data ?? {};
                let watermark_txt = tenantName ?? "东方智投";
                watermark.set(watermark_txt);
            });
        },
    },
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/commonVar.scss";
@media screen and (min-width: 750px) {
    .mask {
        z-index: 998;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
    }
    .mbh-page {
        display: flex;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        .mbh-sidebar,
        .seize-a-seat {
            width: $sidebar-width;
            height: 100vh;
            background: #fff;
        }
        .mbh-sidebar {
            position: fixed;
            overflow: hidden;
        }
        .mbh-main {
            flex: 1;
            @include flex-column-center;
            height: 100vh;
            overflow: hidden;
            .mbh-header {
                width: calc(100vw - $sidebar-width);
                height: $header-height;
            }
            .mbh-content {
                position: relative;
                flex: 1;
                overflow: hidden;
                width: $content-width;
                height: calc(
                    100vh - $header-height - $padding-size-top -
                        $padding-size-bottom
                );
                padding-top: $padding-size-top;
                padding-bottom: $padding-size-bottom;
            }
        }
    }
}
@media screen and (max-width: 750px) {
    .mbh-page {
        display: flex;
        .seize-a-seat {
            display: none;
        }
        .mbh-sidebar {
            display: none;
        }
        .mbh-main {
            flex: 1;
            .mbh-header {
                flex: 1;
                height: $header-height;
            }
        }
        .mbh-content {
            flex: 1;
        }
    }
}
</style>
