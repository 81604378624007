<template>
    <div class="product-content" @scroll="handleScroll">
        <el-empty
            style="margin-top: 50px"
            description="暂无商品卡片"
            v-if="productList.length == 0"
        ></el-empty>
        <div class="product-box" ref="scrollDiv" v-else>
            <ul class="product-list">
                <li v-for="item in productList" :key="item.goodsId">
                    <goodItem :goodData="item">
                        <div class="right-btn">
                            <el-button
                                size="mini"
                                class="btn_b"
                                @click="handleSendCard(item)"
                                >发送卡片</el-button
                            >
                        </div>
                    </goodItem>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { reqGetProductList } from "@/api/customer";
import { SERVICE_CODE } from "@/constant/globalConst";
import mbhText from "@/component/tableText";
import goodItem from "@/component/goodItem";
export default {
    components: {
        mbhText,
        goodItem,
    },
    props: {},
    data() {
        return {
            productList: [],
            pageNum: 1,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        async getList() {
            const params = {
                pageNum: this.pageNum,
                pageSize: 10,
                isAsc: "desc",
                orderByColumn: "goodsId",
            };
            const res = await reqGetProductList(params);
            if (res.code === SERVICE_CODE.SUCCESS) {
                this.productList = this.productList.concat(
                    res.data.records || []
                );
                if (this.productList.length == res.data.total) {
                    // 没有更多了
                    this.pageNum = 0;
                    // this.$message.info("没有更多了");
                }
            }
        },
        handleScroll(e) {
            if (
                e.srcElement.scrollTop + e.srcElement.clientHeight + 1 >
                e.srcElement.scrollHeight
            ) {
                if (this.pageNum == 0) return;
                this.pageNum++;
                this.getList();
            }
        },
        handleSendCard(item) {
            const params = {
                goodsId: item.goodsId,
                msgType: "miniprogrampage",
                productData: item,
            };
            this.$emit("sendMsg", params);
        },
    },
};
</script>
<style lang="scss" scoped>
.product-content {
    width: 375px;
    height: 465px;
    background: #fff;
    overflow: auto;
}

.product-list {
    background: #f9f9f9;
    li {
        box-sizing: border-box;
        margin-bottom: 5px;
        padding: 10px;
        width: 100%;
        background: #ffffff;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>
