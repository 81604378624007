const config = {
    /**
     * @description 网站标题
     */
    title: "商品后台",
    /**
     * @description 是否显示 tagsView
     */
    tagsView: true,
    /**
     * @description 固定头部
     */
    fixedHeader: true,
    /**
     * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
     */
    tokenCookieExpires: 1,
    /**
     * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
     */
    passCookieExpires: 1,
    /**
     * @description token key
     */
    TokenKey: "token",
    /**
     * @description userinfo key
     */
    UserInfoKey: "userInfo",
    /**
     * @description 路由存储 key
     */
    RouterArrKey: "routerArr",
    /**
     * @description 请求超时时间，毫秒（默认20秒）
     */
    timeout: 20000,
    /**
     * @description 是否显示logo
     */
    sidebarLogo: true,
    /**
     * 是否显示设置的底部信息
     */
    showFooter: true,
    /**
     * 底部文字，支持html语法
     */
    footerTxt: "1",
    /**
     * 备案号
     */
    caseNumber: "1",
    // baseURL: "https://admin.mbhad.com",
    // baseURL: "https://32l567117f.eicp.vip", // 线上
    // baseURL: 'http://free.aeert.com:15201', // 线下
    // baseURL: 'https://z234p63508.imdo.co', // 线下
    // baseURL: "http://192.168.2.139:8085", // 线下
    // baseURL: "http://192.168.2.159:8085", // 线下
    // baseURL: 'http://free.aeert.com:14247', // 穿透地址
    // baseURL: 'https://3ff2567117.oicp.vip', // 线下
    // baseURL: 'https://z234p63508.imdo.co', // 线下
    // baseURL: process.env.VUE_APP_BASE_API, // 根据环境获取请求域名
    // baseURL: "https://32l567117f.eicp.vip/", // 线下
    baseURL: process.env.VUE_APP_BASE_API, // /根据环境获取请求域名
    baseWS: process.env.VUE_APP_WS_API,
    /**
     * 小程序首页
     */
    baseAppURL: "pages/goods/details/index",
};
export default config;
