export const SERVICE_CODE = {
    /** 成功 */
    SUCCESS: 200
}

/** 审核状态 */
export const CHECK_STATUS = {
    /** 审核通过 */
    PASS_THROUGH: 0,
    /** 审核驳回 */
    AUDIT_FAILED: 1,
    /** 审核中 */
    IN_REVIEW: 2,
    /** 待审核 */
    AUDIT: 3
}

/** 时间格式 */
export const TIME_FORMAT = "yyyy-MM-dd HH:mm:ss"

/**
 * @description 获取当周时间
 * @param {*} n 1-7
 * @returns 时间
 */
function week(n) {
    const checkDate = new Date();
    checkDate.setDate(checkDate.getDate() + n - (checkDate.getDay() || 7));
    return checkDate;
}

function dateFormat(date) {
    return date < 10 ? '0' + date : date
}
function GMTToStr(time) {
    let date = new Date(time)
    let Str = date.getFullYear() + '-' +
        dateFormat(date.getMonth() + 1) + '-' +
        dateFormat(date.getDate())
    return Str
}
export function getMonthDate() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
    return [GMTToStr(start), GMTToStr(end)]
}

/** 时间筛选快捷 */
export const RANGE_PICKER_OPTION = {
    /** 今日数据 */
    ONE_DAY: {
        text: '今日',
        onClick(picker) {
            const date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            let d = date.getDate();
            const startTime = y + "-" + dateFormat(m) + "-" + dateFormat(d) + " " + "00:00:00";
            const endTime = y + "-" + dateFormat(m) + "-" + dateFormat(d) + " " + "23:59:59";
            picker.$emit('pick', [startTime, endTime]);
        }
    },
    /** 昨日数据 */
    YESTER_DAY: {
        text: '昨日',
        onClick(picker) {
            const temp = new Date();
            temp.setTime(temp.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [new Date(temp.setHours(0, 0, 0, 0)), new Date(temp.setHours(23, 59, 59, 0))]);
        }
    },
    /** 这周 */
    THIS_WEEK: {
        text: '这周',
        onClick(picker) {
            let startTime = week(1)
            let endTime = week(7)
            startTime = startTime.getFullYear() + "-" + dateFormat(startTime.getMonth() + 1) + "-" + dateFormat(startTime.getDate()) + " " + "00:00:00";
            endTime = endTime.getFullYear() + "-" + dateFormat(endTime.getMonth() + 1) + "-" + dateFormat(endTime.getDate()) + " " + "23:59:59";
            picker.$emit('pick', [startTime, endTime]);
        }
    },
    /** 当月 */
    CURRENT_MONTH: {
        text: '当月',
        onClick(picker) {
            const date = new Date()
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            date.setMonth(date.getMonth() + 1);
            date.setDate(0);
            const lastDay = date.getDate();
            const startTime = y + "-" + dateFormat(m) + "-" + "01" + " " + "00:00:00";
            const endTime = y + "-" + dateFormat(m) + "-" + dateFormat(lastDay) + " " + "23:59:59";
            picker.$emit('pick', [startTime, endTime]);
        }
    },
    /** 最近一周 */
    ONE_WEEK: {
        text: '最近一周',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
        }
    },
    /** 最近一个月 */
    ONE_MONTH: {
        text: '最近一个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
        }
    },
    /** 最近三个月 */
    THREE_MONTH: {
        text: '最近三个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
        }
    },
}
function timeFormatConvert(e) {
    const Y = e.getFullYear(); // 年
    const M = e.getMonth() + 1; // 月
    const D = e.getDate(); // 日
    const H = e.getHours(); // 时
    const Mi = e.getMinutes(); // 分
    const S = e.getSeconds(); // 秒
    return Y + "-" + dateFormat(M) + "-" + dateFormat(D) + " " + dateFormat(H) + ":" + dateFormat(Mi) + ":" + dateFormat(S);
}

export const RANGE_PICKER_OPTION_ARR = Object.values(RANGE_PICKER_OPTION)
/** 时间筛选快捷 */
export const RANGE_DATE_OPTION = {
    /** 今日数据 */
    ONE_DAY: {
        active: true,
        text: '今日',
        date() {
            const date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            let d = date.getDate();
            const startTime = y + "-" + dateFormat(m) + "-" + dateFormat(d) + " " + "00:00:00";
            const endTime = y + "-" + dateFormat(m) + "-" + dateFormat(d) + " " + "23:59:59";
            return [startTime, endTime];
        }
    },
    /** 昨日数据 */
    YESTER_DAY: {
        active: false,
        text: '昨日',
        date() {
            const temp = new Date();
            temp.setTime(temp.getTime() - 3600 * 1000 * 24);
            return [timeFormatConvert(new Date(temp.setHours(0, 0, 0, 0))), timeFormatConvert(new Date(temp.setHours(23, 59, 59, 0)))];
        }
    },
    /** 这周 */
    THIS_WEEK: {
        active: false,
        text: '这周',
        date() {
            let startTime = week(1)
            let endTime = week(7)
            startTime = startTime.getFullYear() + "-" + dateFormat(startTime.getMonth() + 1) + "-" + dateFormat(startTime.getDate()) + " " + "00:00:00";
            endTime = endTime.getFullYear() + "-" + dateFormat(endTime.getMonth() + 1) + "-" + dateFormat(endTime.getDate()) + " " + "23:59:59";
            return [startTime, endTime];
        }
    },
    /** 当月 */
    CURRENT_MONTH: {
        active: false,
        text: '当月',
        date() {
            const date = new Date()
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            date.setMonth(date.getMonth() + 1);
            date.setDate(0);
            const lastDay = date.getDate();
            const startTime = y + "-" + dateFormat(m) + "-" + "01" + " " + "00:00:00";
            const endTime = y + "-" + dateFormat(m) + "-" + dateFormat(lastDay) + " " + "23:59:59";
            return [startTime, endTime];
        }
    },
    /** 最近一周 */
    ONE_WEEK: {
        active: false,
        text: '最近一周',
        date() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [timeFormatConvert(start), timeFormatConvert(end)];
        }
    },
    /** 最近一个月 */
    ONE_MONTH: {
        active: false,
        text: '最近一个月',
        date() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [timeFormatConvert(start), timeFormatConvert(end)];
        }
    },
    /** 最近三个月 */
    THREE_MONTH: {
        active: false,
        text: '最近三个月',
        date() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [timeFormatConvert(start), timeFormatConvert(end)];
        }
    },
}
export const RANGE_DATE_OPTION_ARR = Object.values(RANGE_DATE_OPTION)
export const MOCK_GOOD_LIST = [
    {
        "goodsId": "1715655842874966018",
        "coverUrl": "https://oss.mbhad.com/20231021/c2fe44283dd6416e88c9279e2ed8535d.gif",
        "goodsName": "测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试",
        "saleIs": true,
        "homeShowIs": false,
        "createTime": "2023-10-21 17:06:43",
        "createBy": "1",
        "createName": "张梓锐",
        "goodsSizeNum": 1,
        "goodsDes": "1"
    },
    {
        "goodsId": "1715007268520636418",
        "coverUrl": "https://oss.mbhad.com/20231019/006a968ca42b4a378cc03bc5780bb555.png",
        "goodsName": "扫把",
        "saleIs": true,
        "homeShowIs": true,
        "createTime": "2023-10-19 22:09:31",
        "createBy": "1",
        "createName": "张梓锐",
        "goodsSizeNum": 4,
        "goodsDes": "大扫把"
    }
]

export const MERCHANT_TYPE = {
    SINGLE_MERCHANT: 1,
    MERCHANT_GROUP: 2
}

/** 商户状态 */
export const TABS_MERCHANT_STATUS = {
    'UNKNOWN': {
        effect: 'plain',
        type: 'info',
        label: '未知'
    },
    'NORMAL': {
        effect: 'plain',
        type: 'success',
        label: '正常'
    },
    'BAN': {
        effect: 'plain',
        type: 'danger',
        label: '封禁'
    },
    'CONTROL': {
        effect: 'plain',
        type: 'warning',
        label: '疑似风控'
    },
}

/** 投诉状态 */
export const COMPLAIN_STATUS = {
    'PENDING': {
        effect: 'plain',
        type: 'danger',
        label: '待处理'
    },
    'PROCESSING': {
        effect: 'plain',
        type: 'warning',
        label: '处理中'
    },
    'PROCESSED': {
        effect: 'plain',
        type: 'success',
        label: '已完成'
    },
}


/** 拼多多商品报备状态 */
export const TABS_PDD_LIST_STATUS = {
    'CREATED': {
        effect: 'plain',
        type: 'warning',
        label: '已创建'
    },
    'PASSED': {
        effect: 'plain',
        type: 'success',
        label: '已通过'
    },
    'REJECTED': {
        effect: 'plain',
        type: 'danger',
        label: '已驳回'
    },
    'SUBMITTED': {
        effect: 'plain',
        type: 'info',
        label: '已提交'
    },
}

/** 拼多多商品详情审核状态 */
export const DETAIL_PDD_LIST_STATUS = {
    '0': {
        effect: 'plain',
        type: 'warning',
        label: '已创建'
    },
    '2': {
        effect: 'plain',
        type: 'success',
        label: '已通过'
    },
    '3': {
        effect: 'plain',
        type: 'danger',
        label: '已驳回'
    },
    '1': {
        effect: 'plain',
        type: 'info',
        label: '已提交'
    },
}