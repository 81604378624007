<template>
    <div>
        <div class="upload" :style="{ zoom: zoom }">
            <el-upload
                v-if="!listType"
                ref="uploader"
                class="avatar-uploader"
                :action="action"
                :headers="headers"
                :list-type="listType"
                :show-file-list="showFileList"
                :before-upload="beforeAvatarUpload"
                :on-change="handleChange"
            >
                <el-image
                    style="height: 134px; width: 134px"
                    :style="[styleObj]"
                    v-if="imgUrl"
                    :src="imgUrl"
                >
                    <div slot="error" class="image-slot">
                        <img
                            style="width: 134px; height: 134px"
                            src="../../../assets/images/user.png"
                        />
                    </div>
                </el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>

            <el-upload
                :action="action"
                v-else
                :file-list="fileList"
                list-type="picture-card"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :before-upload="beforeUploadImgList"
                :on-remove="handleRemove"
                :on-change="handleChange"
                :limit="limit"
                :disabled="isDisabled"
            >
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }" style="height: 100%">
                    <el-image
                        v-if="containsImageExtension(file.url)"
                        style="width: 100%"
                        :src="file.url"
                        fit="cover"
                    ></el-image>

                    <div
                        v-if="containsVideoExtension(file.url)"
                        class="mbh-flex-col-center"
                        style="height: 100%"
                    >
                        视频上传成功
                    </div>

                    <div
                        v-else
                        class="mbh-flex-col-center"
                        style="height: 100%"
                    >
                        正在加载中
                    </div>

                    <span class="el-upload-list__item-actions">
                        <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                        >
                            <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                            v-if="!isDisabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                        >
                            <i class="el-icon-delete"></i>
                        </span>
                    </span>
                </div>
            </el-upload>
        </div>

        <div>
            <span v-if="extraStr" class="extra-str">{{ extraStr }}</span>
        </div>

        <el-dialog
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            :visible.sync="dialogVisible"
        >
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
import { getToken } from "@/utils/tool.js";
import config from "@/settings";
export default {
    props: {
        isLimitSize: {
            type: Boolean,
            default: false,
        },
        imgSize: {
            type: Number,
            default: 2,
        },
        zoom: {
            type: Number,
            default: 1,
        },
        size: {
            type: Object,
            default: () => {},
        },
        styleObj: {
            type: Object,
            default: () => {},
        },
        showFileList: {
            type: Boolean,
            default: false,
        },
        listType: {
            type: String,
            default: "",
        },
        extraStr: {
            type: String,
            default: "",
        },
        url: {
            type: Object,
            default: () => {},
        },
        listImg: {
            type: Array,
            default: () => [],
        },
        limit: {
            type: Number,
            default: 20,
        },
    },
    watch: {
        url: {
            handler() {
                if (this.url) {
                    this.imgUrl = this.url.data;
                }
            },
            immediate: true,
            deep: true, //开启深度监听
        },
        listImg: {
            handler() {
                if (this.listImg && this.listImg.length) {
                    if (!this.listType) {
                        this.imgUrl =
                            this.listImg[this.listImg.length - 1].data;
                    }
                    this.fileList = this.listImg.map((v) => {
                        return {
                            name: v.name,
                            url: v.data,
                        };
                    });
                }
            },
            immediate: true,
            deep: true, //开启深度监听
        },
    },
    data() {
        return {
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            imgUrl: "",
            action: config.baseURL + "/api/file/upload",
            headers: {
                Token: getToken(),
            },
            isDisabled: false,
            commonImageExtensions: /\.(jpg|jpeg|png|gif|bmp|webp)$/i,
            commonVideoExtensions: /\.(mp4|Flash|AVI|gif|mkv|mov)$/i,
        };
    },
    created() {},
    methods: {
        handleRemove(file, fileList) {
            this.fileList = this.fileList.filter(
                (item) => item.name !== file.name
            );
            this.$emit("handleImgRemove", { name: file.name });
        },
        containsImageExtension(str) {
            return this.commonImageExtensions.test(str);
        },
        containsVideoExtension(str) {
            return this.commonVideoExtensions.test(str);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleImg() {
            document.querySelector("input").click();
        },
        handleChange(file, fileList) {
            const { status, response, name: fileName, raw } = file;
            const name = this.$tool?.getOnlyId?.() + fileName;

            const returnFile = {
                name,
                type: raw.type.split?.("/")?.[0],
            };

            if (status === "uploading" || status === "ready") {
                this.isDisabled = true;
                this.$tool.isBetaConsole("等待的上传数据file", file);
            }

            if (status === "success") {
                this.isDisabled = false;
                if (response.code === 200) {
                    returnFile.url = response.data;
                    returnFile.data = response.data;
                    this.imgUrl = response.data;
                }
                this.$emit("handleImgSuccess", returnFile);
            }

            if (status === "fail") {
                this.isDisabled = false;
                this.$tool.isBetaConsole("失败上传的数据file", file);
                this.$message.error("上传失败");
            }
        },
        limitFileWH(E_width, E_height, file) {
            const _this = this;
            let imgWidth = "";
            let imgHight = "";
            const isSize = new Promise(function (resolve, reject) {
                const width = E_width;
                const height = E_height;
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                img.onload = function () {
                    imgWidth = img.width;
                    imgHight = img.height;
                    const valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return true;
                },
                () => {
                    _this.$message.warning({
                        message:
                            "上传图片的尺寸应为" +
                            E_width +
                            "*" +
                            E_height +
                            "，当前上传图片的尺寸为：" +
                            imgWidth +
                            "*" +
                            imgHight,
                        btn: false,
                    });
                    return false;
                }
            );
            return isSize;
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error("图片大小不能超过 2MB!");
            }
            if (!this.size) {
                return isLt2M;
            }
            // 调用[限制图片尺寸]函数
            let flag = false;
            const width = this.size?.width ?? this.styleObj?.width ?? "340";
            const height = this.size?.height ?? this.styleObj?.height ?? "340";
            this.limitFileWH(width, height, file).then((res) => {
                flag = res;
                return isLt2M && flag;
            });
        },
        beforeUploadImgList(file) {
            if (!this.isLimitSize) {
                return true;
            }
            const isLt2M = file.size / 1024 / 1024 < this.imgSize;

            if (!isLt2M) {
                this.$message.error(`不能超过 ${this.imgSize}MB!`);

                setTimeout(() => {
                    this.isDisabled = false;
                });

                return Promise.reject(false);
            } else {
                return true;
            }
        },
    },
};
</script>
<style>
.el-upload-list--picture-card .el-upload-list__item {
    -webkit-transition: none !important;
    transition: none !important;
}
</style>
<style lang="scss" scoped>
// .upload-img>div>div{
//   display: flex;
//   flex-wrap: wrap;
// }
$size: 134px;
// .el-upload-list--picture-card .el-upload-list__item,
// .el-upload {
//   zoom: 1;
// }
.el-upload-list__item {
    transition: none !important;
}

.extra-str {
    color: #ccc;
}

.avatar-uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    background: #f9f9f9;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: $size;
    height: $size;
    line-height: $size;
    text-align: center;
}
.avatar {
    width: $size;
    height: $size;
    display: block;
}
</style>
