<template>
    <div class="content-box">
        <div class="info" v-if="type !== 'card'">
            <img
                :width="coverStyle.width"
                :height="coverStyle.height"
                :src="goodData.coverUrl || goodData.goodsThumbnailUrl"
                alt=""
                class="good-img"
            />
            <div class="right-info">
                <template v-if="goodData.goodsName">
                    <mbhText
                        :labelConfig="{ text: '名称' }"
                        :contentConfig="{ text: goodData.goodsName }"
                    />
                </template>
                <template v-if="goodData.meal">
                    <mbhText
                        :labelConfig="{ text: '规格' }"
                        :contentConfig="{ text: goodData.meal }"
                        :commonStyle="{ color: '#a1a1a1' }"
                    />
                </template>
                <template v-if="goodData.num || goodData.goodsQuantity">
                    <mbhText
                        :labelConfig="{ text: '数量' }"
                        :contentConfig="{
                            text: goodData.num || goodData.goodsQuantity,
                        }"
                        :commonStyle="{ color: '#a1a1a1' }"
                    />
                </template>
                <template v-if="goodData.goodsDes">
                    <mbhText
                        :labelConfig="{ text: '描述' }"
                        :contentConfig="{ text: goodData.goodsDes }"
                        :commonStyle="{ color: '#a1a1a1' }"
                    />
                </template>
                <template v-if="goodData.goodsId">
                    <mbhText
                        :labelConfig="{ text: 'ID' }"
                        :contentConfig="{ text: goodData.goodsId }"
                        :commonStyle="{ color: '#a1a1a1' }"
                    />
                </template>
                <template v-if="goodData.pddGoodsId">
                    <mbhText
                        :labelConfig="{ text: '商品ID' }"
                        :contentConfig="{ text: goodData.pddGoodsId }"
                        :commonStyle="{ color: '#a1a1a1' }"
                    />
                </template>
                <template v-if="goodData.goodsSign">
                    <mbhText
                        :labelConfig="{ text: 'goodsSign' }"
                        :contentConfig="{ text: goodData.goodsSign }"
                        :commonStyle="{ color: '#a1a1a1' }"
                    />
                </template>
                <template v-if="goodData.goodsPrice">
                    <mbhText
                        :labelConfig="{ text: '商品价格' }"
                        :contentConfig="{
                            text: $tool.fenToYuan(goodData.goodsPrice),
                        }"
                        :commonStyle="{ color: '#a1a1a1' }"
                    />
                </template>
            </div>
            <slot />
        </div>

        <div v-if="type === 'card'" class="card">
            <div class="card-app">
                <img :src="goodDataToCard.imagesUrl" alt="" class="app-logo" />
                <span class="app-name">{{
                    goodDataToCard.miniProgramName
                }}</span>
            </div>
            <div class="card-goodName">
                {{ goodDataToCard.goodsName }}
            </div>
            <div class="card-cover">
                <img :src="goodDataToCard.imagesUrl" alt="" />
            </div>
            <div class="card-bottom">小程序</div>
        </div>
    </div>
</template>
<script>
import mbhText from "@/component/tableText";
export default {
    components: {
        mbhText,
    },
    /**
     * @description 商品单张卡片
     * @params labelConfig text：文案 style:样式 max:最大占几行
     */
    props: {
        goodData: {
            type: Object,
            required: false,
            default: () => {
                return {
                    coverUrl: "",
                    goodsId: "",
                    goodsName: "",
                    goodsDes: "",
                    meal: "",
                    num: "",
                };
            },
        },
        type: {
            type: String,
            required: false,
            default: "item",
        },
        cardData: {
            type: String,
            required: false,
            default: "{}",
        },
        coverStyle: {
            type: Object,
            required: false,
            default: () => {
                return {
                    width: "64",
                    height: "64",
                };
            },
        },
    },
    data() {
        return {
            goodDataToCard: {
                goodsName: "商品名称",
                imagesUrl:
                    "https://oss.mbhad.com/20231110/9976d58f7b044cfc9804109347825f3f.webp",
            },
        };
    },
    created() {
        if (this.type === "card") {
            this.processingCardData();
        }
    },
    methods: {
        processingCardData() {
            let res = {};
            try {
                res = JSON.parse(this.cardData);
                this.goodDataToCard = res;
            } catch (error) {}
        },
    },
};
</script>

<style lang="scss" scoped>
.content-box {
    width: 100%;
}
.info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    img {
        margin-right: 6px;
    }
    .msg {
        color: #a1a1a1;
    }
    .good-img {
        border-radius: 2px;
    }
}
.right-info {
    width: auto;
    margin-right: 8px;
}
.card {
    img {
        width: 240px;
        height: 268px;
        object-fit: cover;
    }
    .card-app {
        display: flex;
        align-items: center;
        color: #ccc;
        margin-bottom: 5px;
        font-size: 12px;
        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    .card-bottom {
        border-top: 1px solid #e4dfdf;
        padding: 5px 0px;
        margin-top: 5px;
        font-size: 12px;
        color: #ccc;
    }
}
</style>
