<template>
    <div class="header">
        <!-- <i  class="iconfont icon-daiban"></i> -->
        <el-tooltip
            class="item"
            effect="light"
            content="通知"
            placement="bottom"
        >
            <img
                v-if="adminIs"
                style="margin-left: 20px"
                @click="$router.push('/inform')"
                width="30"
                height="30"
                src="../../assets/images/tongzhi.png"
                alt=""
            />
        </el-tooltip>

        <el-tooltip
            class="item"
            effect="light"
            content="短信"
            placement="bottom"
        >
            <img
                v-if="adminIs"
                style="margin-left: 20px"
                @click="$router.push('/sendMsg')"
                width="30"
                height="30"
                src="../../assets/images/liuyan.svg"
                alt=""
            />
        </el-tooltip>

        <!-- <i class="iconfont icon-lingdang"></i> -->
        <!-- <el-button type="warning" @click="outlogin">退出登录</el-button> -->
        <el-dropdown>
            <span class="el-dropdown-link">
                <img
                    width="30"
                    height="30"
                    src="../../assets/images/shezhi.svg"
                    alt=""
                />
                <!-- 下拉菜单<i class="el-icon-arrow-down el-icon--right"></i> -->
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                    <div @click="changePass('info')">修改资料</div>
                </el-dropdown-item>
                <el-dropdown-item>
                    <div @click="changePass('pass')">修改密码</div>
                </el-dropdown-item>
                <el-dropdown-item>
                    <div @click="outlogin">退出登录</div>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button type="primary">待办事项</el-button>
    <el-button type="primary">通知</el-button> -->
        <checkPass ref="checkPass" />
        <div class="company-name">{{ $store.state?.user?.tenantName }}</div>
        <android-sidebar></android-sidebar>
    </div>
</template>
<script>
import { outLogin } from "@/api/login";
import { clearUserStorage } from "@/utils/tool";
import checkPass from "./checkPass.vue";
import androidSidebar from "./androidSidebar.vue";
export default {
    components: { checkPass, androidSidebar },
    data() {
        return {
            adminIs: false,
        };
    },
    created() {
        this.adminIs = JSON.parse(sessionStorage.getItem("userInfo")).adminIs;
    },
    methods: {
        changePass(val) {
            this.$refs.checkPass.show(true, val);
        },
        async outlogin() {
            this.$modal.loading();
            try {
                const data = await outLogin();
                this.$tool.isBetaConsole(data);
                if (data.code == 200) {
                    clearUserStorage();
                    this.$router.push("/login");
                }
                this.$modal.closeLoading();
            } catch (e) {
                this.$modal.closeLoading();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.header {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    text-align: right;
    box-sizing: border-box;
    padding-right: 30px;
    img {
        cursor: pointer;
    }
    .el-button,
    .iconfont,
    .company-name {
        margin-left: 20px;
        color: #fff;
    }
    .company-name {
        font-size: 16px;
        margin-right: 20px;
        color: black;
    }
}
.el-dropdown-link {
    cursor: pointer;
    color: #fff;
}
.el-icon-arrow-down {
    font-size: 12px;
}
@media (max-width: 750px) {
    .company-name {
        display: none;
    }
}
</style>
