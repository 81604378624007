import axios from "axios";
import config from "../settings";
import { Message, Notification } from "element-ui";
import router from "@/router";
import { clearUserStorage, getToken } from "./tool";


const service = axios.create({
    baseURL: config.baseURL,
    timeout: config.timeout,
});

service.interceptors.request.use(
    (config) => {

        if (config.url.includes("/api/top_up/wx_result")) {
            delete config.timeout;
        }
        const { isBetaConsole } = window.__LIN__?.tool ?? {}
        isBetaConsole('请求', config, 'request')
        for (let key in config.data) {
            if (
                config.data[key] === "" ||
                config.data[key] === null ||
                config.data[key] === undefined
            ) {
                delete config.data[key];
            }
        }
        for (let key in config.params) {
            if (
                config.params[key] === "" ||
                config.params[key] === null ||
                config.params[key] === undefined
            ) {
                delete config.params[key];
            }
        }
        config.headers["Token"] = getToken();
        config.headers["Content-Type"] = "application/json;charset=utf-8";
        return config;
    },
    (error) => {
        return Promise.reject();
    }
);

service.interceptors.response.use(
    (response) => {
        const { isBetaConsole } = window.__LIN__?.tool ?? {}
        isBetaConsole('响应', response.config, 'request')
        // console.log(" process.env.NODE_ENV ", process.env.VUE_APP_BASE_API);
        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data;
            } else if (
                response.data.code === 202 ||
                response.data.code === 203 ||
                response.data.code === 204 ||
                response.data.code === 205
            ) {
                Message.error("登录过期,请重新登录");
                clearUserStorage();
                // sessionStorage.removeItem("routerArr");
                // sessionStorage.removeItem("userInfo");
                // sessionStorage.removeItem("token");
                router.push("/login");
                return response.data;
            } else if (response.data.code === 209) {
                Message.error("请先登录");
                router.push("/login");
                return response.data;
            } else if (response.data.code === 206) {
                Message.error("账号已被封禁");
                clearUserStorage();
                router.push("/login");
                return response.data;
            } else if (response.data.code === 500) {
                Message.error(response.data.msg);

                return response.data;
            } else if (response.data.code === 210) {
                Message.error("暂无权限,请重新登录");
                clearUserStorage();
                router.push("/login");
                return response.data;
            } else {
                Message.error(response.data.message);
                return response.data;
                // return Promise.reject(response.data);
            }
        } else {
            return Promise.reject(response.data);
        }
    },
    (error) => {
        // 兼容blob下载出错json提示
        if (
            error?.response?.data?.data instanceof Blob &&
            error?.response?.data?.type?.toLowerCase?.()?.indexOf("json") !== -1
        ) {
            const reader = new FileReader();
            reader.readAsText(error.response.data, "utf-8");
            reader.onload = function (e) {
                const errorMsg = JSON.parse(reader.result).message;
                return Notification.error({
                    title: errorMsg,
                    duration: 5000,
                });
            };
        } else {
            if (error.toString().indexOf("Error: timeout") !== -1) {
                return Notification.error({
                    title: "网络请求超时，请刷新",
                    duration: 5000,
                });
            } else {
                return Notification.error({
                    title: "系统更新中，请稍等",
                    duration: 5000,
                });
            }
        }
        return Promise.reject(error);
    }
);
const httpInfo = {
    httpRequest: service,
    Host: config.baseURL,
};

export default httpInfo;
