<template>
    <div class="mbh-text">
        <label
            v-show="labelConfig.text"
            class="mbh-text-label"
            :style="{ ...commonStyle, ...labelConfig.style }"
            >{{ labelConfig.text }}:</label
        >
        <el-tooltip
            class="item"
            effect="dark"
            placement="top-start"
            :disabled="tooltipDisabled"
        >
            <div slot="content">
                <div v-for="(item, index) in splitTextArr" :key="index">
                    {{ item }}
                </div>
            </div>
            <div
                class="content"
                :style="{
                    ...commonStyle,
                    ...contentConfig.style,
                    '-webkit-line-clamp': contentConfig.max,
                }"
                ref="elipsisDom"
                @mouseenter="spanMouseenter($event)"
            >
                {{ contentConfig.text }}
            </div>
        </el-tooltip>
    </div>
</template>
<script>
export default {
    /**
     * @description
     * @params labelConfig text：文案 style:样式 max:最大占几行
     */
    props: {
        labelConfig: {
            type: Object,
            required: false,
            default: () => {
                return {
                    text: "",
                    style: {},
                    max: 1,
                };
            },
        },
        contentConfig: {
            type: Object,
            required: true,
            default: () => {
                return {
                    text: "具体描述111",
                    style: {},
                    max: 1,
                };
            },
        },
        commonStyle: {
            type: Object,
            required: false,
            default: () => {
                return {
                    color: "#000000",
                };
            },
        },
    },
    data() {
        return {
            tooltipDisabled: true,
            splitTextArr: [],
        };
    },
    created() {
        this.getSplitText();
    },
    methods: {
        spanMouseenter(ev) {
            if (ev.target.clientHeight < ev.target.scrollHeight) {
                this.tooltipDisabled = false;
            } else {
                this.tooltipDisabled = true;
            }
        },
        splitStringByChars(str, chunkSize) {
            let chunks = [];
            for (let i = 0; i < str.length; i += chunkSize) {
                chunks.push(str.slice(i, i + chunkSize));
            }
            return chunks;
        },
        getSplitText() {
            if (!this.contentConfig.text) {
                return [this.contentConfig.text];
            }
            const textArr = this.splitStringByChars(
                this.contentConfig.text,
                40
            );
            this.splitTextArr = textArr;
        },
    },
    watch: {
        contentConfig: {
            handler(val) {
                this.getSplitText();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.mbh-text {
    width: 100%;
    display: flex;
    .mbh-text-label {
        margin-right: 4px;
        white-space: nowrap;
    }
    .content {
        overflow: hidden;
        text-overflow: ellipsis;
        /* 将对象作为弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 限制在一个块元素显示的文本的行数 */
        /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
        -webkit-line-clamp: 1;
        /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-box-orient: vertical;
    }
}
</style>
