import * as Api from "@/utils/request.js";
let httpRequest = Api.default.httpRequest;
// 角色列表
export function roleList(params) {
    return httpRequest({
        url: `/api/role`,
        params,
        method: "GET",
    });
}
// 角色下拉条
export function roleDown(params) {
    return httpRequest({
        url: `/api/role/down`,
        params,
        method: "GET",
    });
}
// 用户菜单树
export function menuTree(params) {
    return httpRequest({
        url: `/api/role/menuTree`,
        params,
        method: "GET",
    });
}
// 新增角色
export function addRole(data) {
    return httpRequest({
        url: `/api/role`,
        data,
        method: "POST",
    });
}
// 角色验重
export function roleVerify(data) {
    return httpRequest({
        url: `/api/role/verify`,
        data,
        method: "POST",
    });
}
// 编辑角色
export function editRole(data, roleId) {
    return httpRequest({
        url: `/api/role/${roleId}`,
        data,
        method: "PUT",
    });
}
// 删除角色
export function delRole(roleId) {
    return httpRequest({
        url: `/api/role/${roleId}`,
        method: "DELETE",
    });
}
// 用户列表
export function userList(params) {
    return httpRequest({
        url: `/api/user`,
        params,
        method: "GET",
    });
}
// 新增用户
export function addUser(data) {
    return httpRequest({
        url: `/api/user`,
        data,
        method: "POST",
    });
}
// 用户验重
export function userVerify(data) {
    return httpRequest({
        url: `/api/user/verify`,
        data,
        method: "POST",
    });
}
// 删除用户
export function delUser(userId) {
    return httpRequest({
        url: `/api/user/${userId}`,
        method: "DELETE",
    });
}
// ip 禁用/启用
export function stateIP(state, userId, params) {
    return httpRequest({
        url: `/api/user/${state}/${userId}`,
        params,
        method: "PUT",
    });
}
// 重置密码
export function editPass(params, userId) {
    return httpRequest({
        url: `/api/user/password/${userId}`,
        params,
        method: "PUT",
    });
}
// 编辑用户
export function editUser(data, userId) {
    return httpRequest({
        url: `/api/user/${userId}`,
        data,
        method: "PUT",
    });
}
// 获取跳转地址
export function getSkip(params) {
    return httpRequest({
        url: `/api/skip`,
        params,
        method: "GET",
    });
}
// 重置密码
export function checkPassword(params) {
    return httpRequest({
        url: `/api/user/password`,
        params,
        method: "PUT",
    });
}
// 用户信息
export function checkUserInfo(params) {
    return httpRequest({
        url: `/api/user/`,
        params,
        method: "PUT",
    });
}
// 修改角色状态
export function changeState(state, roleId) {
    return httpRequest({
        url: `/api/role/${state}/${roleId}`,
        method: "PUT",
    });
}
// 客服 禁用/启用
export function changeCusState(customState, userId) {
    return httpRequest({
        url: `/api/user/custom/${customState}/${userId}`,
        method: "PUT",
    });
}
