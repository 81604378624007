import axios from "axios";
import Config from "../settings";
import { Message } from "element-ui";
import { getToken, removeEmpty } from "../utils/tool";

// 创建axios对象
const instance = axios.create();

/**
 * 对get参数进行URI编码并输出query字符串
 */
const encodeGetParams = (body) => {
  if (!body) {
    return "";
  }
  let newBody = { ...body };
  let temp = [];
  for (let k in newBody) {
    temp.push(`${k}=${encodeURIComponent(newBody[k])}`);
  }
  return `${temp.join("&")}`;
};

/**
 * @description 文件下载接口
 * @param url 请求接口
 * @param body 请求参数
 * @param options 请求方法
 * 举例： fileDownload('/api/wx_goods_order/export', {a:1,b:1}, {
      method: 'get',
    });
 */
function fileDownload(url, body, options = {}) {
  body = removeEmpty(body);
  const { method = "get", withToken = true } = options;
  return instance({
    url,
    data: method.toLowerCase() == "post" ? body : {},
    params: method.toLowerCase() == "get" ? body : {},
    method: method,
    baseURL: Config.baseURL, // 请求地址
    timeout: Config.timeout,
    headers: {
      token: getToken(), // token
    },
    responseType: "blob",
    // 自定义参数序列化方法
    paramsSerializer: (params) => {
      return encodeGetParams(params);
    },
    ...options,
  }).then((res) => {
    const reader = new FileReader(); // 创建FileReader实例
    if (res.data.type === "application/json") {
      reader.readAsText(res.data, "utf-8"); // 读取文件, 用字符串显示
      reader.onload = () => {
        let data = JSON.parse(reader.result ?? "{}");
        if (data.code !== 200) {
          Message.error(data.message);
        }
      };
    } else {
      // 获取文件名 后端需要在响应头增加filename，这样导出的文件名也由后端进行控制
      const { filename = "导出订单列表表格" } = res.headers;
      // 兼容IE和Edge
      if ("ActiveXObject" in window || navigator.userAgent.indexOf("Edge") > -1) {
        window.navigator?.msSaveBlob(res.data, decodeURIComponent(filename));
        return res;
      }
      reader.readAsDataURL(res.data);
      reader.onload = (e) => {
        let a = document.createElement("a");
        a.download = decodeURIComponent(filename);
        a.href = e.target.result;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      return res;
    }
  });
}

export default {
  fileDownload,
};
