import Vue from "vue";
import Vuex from "vuex";
import { userDown_, userInfo, getThresholdInfo } from "@/api/common";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        customerShow: false, //客服聊天弹窗
        creatNameList: [], //负责人数组
        thresholdInfo: {
            threshold: "",
            thresholdState: false,
        }, //租户阈值
        permissions: [], //权限按钮
        user: {},
        loading: false, //刷新
        isWS: 0, //是否打开ws 1 开 0关
        newMsg: 0, //1 有新消息 0 没有
    },
    mutations: {
        setNewMsg(state, data) {
            state.newMsg = data;
        },
        setWS(state, data) {
            state.isWS = data;
            sessionStorage.setItem("isWS", data);
        },
        setCustomer(state) {
            state.customerShow = !state.customerShow;
        },
        setCreatList(state, data) {
            state.creatNameList = data;
        },
        setThresholdInfo(state, data) {
            state.thresholdInfo = data;
        },
        setPermissions(state, data) {
            state.permissions = data;
        },
        setUserInfo(state, data) {
            state.user = data;
        },
        setLoading(state) {
            state.loading = !state.loading;
        },
    },
    actions: {
        // 设置用户信息
        setUser({ commit }) {
            commit("setLoading");
            userInfo().then((res) => {
                console.log(res);
                if (res.code == 200) {
                    commit("setUserInfo", res.data);
                }
                commit("setLoading");
            });
        },
        // 设置负责人
        setHead({ commit }) {
            userDown_().then((res) => {
                commit("setCreatList", res.data);
            });
        },
        // 获取租户阈值
        setThreshold({ commit }) {
            getThresholdInfo().then((res) => {
                commit("setThresholdInfo", res.data);
            });
        },
    },
    modules: {},
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            reducer(val) {
                return {
                    creatNameList: val.creatNameList,
                    permissions: val.permissions,
                    user: val.user,
                    thresholdInfo: val.thresholdInfo,
                };
            },
        }),
    ],
});
