import * as Api from "@/utils/request.js";
let httpRequest = Api.default.httpRequest;
// 客服会话列表
export function sessionList() {
    return httpRequest({
        url: `/api/custom/session`,
        method: "GET",
    });
}
// 删除客服会话
export function delSession(clientUserId) {
    return httpRequest({
        url: `/api/custom/session/${clientUserId}`,
        method: "DELETE",
    });
}
// 客服在线列表
export function customList() {
    return httpRequest({
        url: `/api/custom/online`,
        method: "GET",
    });
}
// 获取聊天记录
export function getMsg(params) {
    return httpRequest({
        url: `/api/custom/getMsg`,
        params,
        method: "GET",
    });
}
// 获取图片素材
export function getTempMedia(params) {
    return httpRequest({
        url: `/api/custom/getTempMedia`,
        params,
        method: "GET",
    });
}
// 切换客服
export function customCut(clientUserId, adminUserId) {
    return httpRequest({
        url: `/api/custom/cut/${clientUserId}/${adminUserId}`,
        method: "PUT",
    });
}
// 发送消息
export function sendMsg(data) {
    return httpRequest({
        url: `/api/custom/sendMsg`,
        data,
        method: "POST",
    });
}
// 修改已读状态
export function readMsg(clientUserId) {
    return httpRequest({
        url: `/api/custom/read/${clientUserId}`,
        method: "PUT",
    });
}

/**
 * @description 新增快捷短语
 */
export function reqAddShortcut(data) {
    return httpRequest({
        url: `/api/custom/shortcut_language`,
        method: "POST",
        data,
    });
}

/**
 * @description 得到快捷短语
 */
export function reqGetShortcut() {
    return httpRequest({
        url: `/api/custom/shortcut_language`,
        method: "GET",
    });
}

/**
 * @description 获取商品卡片列表
 */
export function reqGetProductList(params) {
    return httpRequest({
        url: `/api/custom/goods`,
        method: "GET",
        params,
    });
}