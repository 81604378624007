import * as Api from "@/utils/request.js";
let httpRequest = Api.default.httpRequest;
// 文件上传
export function fileUpload(data) {
  return httpRequest({
    url: `/api/file/upload`,
    data,
    method: "POST",
  });
}
// 新增商品-信息-套餐-详情积木
export function getAddData(data) {
  return httpRequest({
    url: `/api/goods/createGoodsBase`,
    data,
    method: "POST",
  });
}

// 商品列表
export function getGoodsList(params) {
  return httpRequest({
    url: `/api/goods`,
    params,
    method: "GET",
  });
}
// 商品详情-回显
export function getGoodsEditList(id, params) {
  return httpRequest({
    url: `/api/goods/${id}`,
    params,
    method: "GET",
  });
}
// 商品规格-回显
// export function getGoodsMealEditList(id, params) {
//   return httpRequest({
//     url: `/api/goods/meal/${id}`,
//     params,
//     method: "GET",
//   });
// }
// 删除商品
export function getGoodsDel(goodsId) {
  return httpRequest({
    url: `/api/goods/${goodsId}`,
    method: "DELETE",
  });
}
// 商品列表批量删除
export function getGoodsAllDel(data) {
  return httpRequest({
    url: `/api/goods/batch`,
    data,
    method: "DELETE",
  });
}

// 是否首页展示
export function kownHomeShow(goodsId, homeShowIs, data) {
  return httpRequest({
    url: `/api/goods/${goodsId}/home_show/${homeShowIs}`,
    data,
    method: "PUT",
  });
}
// 上下架
export function kownSaleIsShow(goodsId, saleIs, data) {
  return httpRequest({
    url: `/api/goods/${goodsId}/is_sale/${saleIs}`,
    data,
    method: "PUT",
  });
}
// 获取商品小程序码
export function getGoodSqr_code(params) {
  return httpRequest({
    url: `/api/goods/qr_code`,
    params,
    method: "GET",
  });
}
// 小程序下拉条
export function getGoodmini_program() {
  return httpRequest({
    url: `/api/mini_program/down`,
    method: "GET",
  });
}

// 修改商品
export function editGoods(goodsId, data) {
  return httpRequest({
    url: `/api/goods/${goodsId}`,
    data,
    method: "PUT",
  });
}

// 售后列表
export function getGoodAfter_sale(params) {
  return httpRequest({
    url: `/api/after_sale`,
    params,
    method: "GET",
  });
}
// 获取订单详情
export function wx_goods_orderInfo(orderId) {
  return httpRequest({
    url: `/api/wx_goods_order/info/${orderId}`,
    method: "GET",
  });
}

// 物流查询
export function wx_goods_orderLogistic(params) {
  return httpRequest({
    url: `/api/wx_goods_order/logistic/`,
    params,
    method: "GET",
  });
}

// 审核通过
export function after_saleOk_audit(afterSaleId, refundAmount) {
  return httpRequest({
    url: `/api/after_sale/ok_audit/${afterSaleId}/${refundAmount}`,
    method: "PUT",
  });
}
// 售后详情
export function after_saleInfo(afterSaleId) {
  return httpRequest({
    url: `/api/after_sale/info/${afterSaleId}`,
    method: "GET",
  });
}
// 拒绝
export function after_saleNo_audit(afterSaleId) {
  return httpRequest({
    url: `/api/after_sale/no_audit/${afterSaleId}`,
    method: "PUT",
  });
}
// 售后地址
export function after_saleAddr(data) {
  return httpRequest({
    url: `/api/after_sale/addr`,
    data,
    method: "POST",
  });
}
// 订单退款
export function after_saleRefund(afterSaleId) {
  return httpRequest({
    url: `/api/after_sale/refund/${afterSaleId}`,
    method: "PUT",
  });
}
// 售后详情
export function getAfter_saleAddr(params) {
  return httpRequest({
    url: `/api/after_sale/addr`,
    params,
    method: "GET",
  });
}
// 短信提醒状态
export function sendMessageState(params) {
  return httpRequest({
    url: `/api/sms/state`,
    params,
    method: "GET",
  });
}
// 状态:开启/关闭
export function after_saleSendMessage(data) {
  return httpRequest({
    url: `/api/sms/state`,
    data,
    method: "PUT",
  });
}
// 修改收货地址
export function wx_goods_orderShipping_addr(data, orderId) {
  return httpRequest({
    url: `/api/wx_goods_order/shipping_addr/${orderId}`,
    data,
    method: "PUT",
  });
}
// 批量拒绝
export function batch_no_audit(data) {
  return httpRequest({
    url: `/api/after_sale/batch_no_audit`,
    data,
    method: "PUT",
  });
}

// 运维管理
export function freight(data) {
  return httpRequest({
    url: `/api/freight`,
    data,
    method: "POST",
  });
}
// 运费模版下拉条
export function getFreightDown(params) {
  return httpRequest({
    url: `/api/freight/down`,
    params,
    method: "GET",
  });
}

// 修改运费模版
export function editFreight(data, freightId) {
  return httpRequest({
    url: `/api/freight/${freightId}`,
    data,
    method: "PUT",
  });
}
// 根据商品ID修改运费模版
export function editGoodsFreight(freightId, goodsId) {
  return httpRequest({
    url: `/api/goods/${goodsId}/${freightId}`,
    method: "PUT",
  });
}
