var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order",on:{"scroll":_vm.handleScroll}},[_c('div',{staticClass:"order-search"},[_c('el-input',{attrs:{"clearable":"","placeholder":"订单搜索,点击键盘enter搜素","prefix-icon":"el-icon-search"},on:{"change":_vm.search},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}),_c('el-tooltip',{attrs:{"content":"刷新","placement":"top"}},[_c('span',{staticClass:"el-icon-refresh",on:{"click":_vm.handleReloadIcon}})])],1),(_vm.List.length == 0)?_c('el-empty',{staticStyle:{"margin-top":"50px"},attrs:{"description":"暂无订单"}}):_c('div',{ref:"scrollDiv",staticClass:"order-box"},[_c('ul',{staticClass:"order-list"},_vm._l((_vm.List),function(item){return _c('li',{key:item.orderId,on:{"click":function($event){return _vm.details(item.orderId)}}},[_c('div',{staticClass:"title"},[_c('p',{},[_vm._v("订单号:"+_vm._s(item.orderId))]),_c('span',[_vm._v(_vm._s(_vm.ORDER_STATUS[`${item.orderState}`]))])]),_c('div',{staticClass:"order-info"},[_c('img',{attrs:{"src":item.coverUrl,"alt":""}}),_c('div',{staticStyle:{"margin-left":"8px"}},[_c('tableText',{attrs:{"contentConfig":{
                                text: item.goodsName,
                            }}}),_c('tableText',{attrs:{"contentConfig":{
                                text: item.meal,
                                max: 2,
                                style: { color: '#6f7070' },
                            }}})],1),_c('div',{staticClass:"price"},[_c('span',{staticClass:"money"},[_vm._v(_vm._s(_vm.amountInYuan(item.totalPrice)))]),_c('span',{staticClass:"num"},[_vm._v("共"+_vm._s(item.num)+"件")])])]),_c('span',{staticClass:"time"},[_vm._v("下单时间 "+_vm._s(item.createTime))]),_c('div',{staticClass:"btn"},[(
                            item.orderState === 'NO_PAID' ||
                            item.orderState === 'WAIT_SHOP'
                        )?_c('span',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['goods:order:up_shipping_addr']),expression:"['goods:order:up_shipping_addr']"}],on:{"click":function($event){$event.stopPropagation();return _vm.toOrder(item)}}},[_vm._v("修改地址")]):_vm._e(),(item.orderState == 'WAIT_SHOP')?_c('span',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['goods:order:deliver']),expression:"['goods:order:deliver']"}],on:{"click":function($event){$event.stopPropagation();return _vm.toOrder(item)}}},[_vm._v("去发货")]):_vm._e(),(
                            item.orderState == 'WAIT_SHOP' ||
                            item.orderState == 'WAIT_SIGN' ||
                            item.orderState == 'SUCCESS'
                        )?_c('span',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['goods:order:refund']),expression:"['goods:order:refund']"}],on:{"click":function($event){$event.stopPropagation();return _vm.toOrder(item)}}},[_vm._v(" 去退款 ")]):_vm._e(),(item.orderState == 'WAIT_SIGN')?_c('span',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['goods:order:up_deliver']),expression:"['goods:order:up_deliver']"}],on:{"click":function($event){$event.stopPropagation();return _vm.toOrder(item)}}},[_vm._v("修改物流")]):_vm._e()])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }