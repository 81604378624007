<template>
    <div class="content-right-shortcut" ref="scrollDiv">
        <div class="short-cut-arr">
            <div
                class="short-cut-item"
                v-for="item in Object.keys(shortData)"
                :key="item"
            >
                <div
                    class="item-text"
                    @click="handleClickShortText(shortData[item])"
                >
                    <tableText
                        :contentConfig="{
                            text: shortData[item],
                            style: { color: '#a1a1a1' },
                            max: 3,
                        }"
                        :updateText="updateText"
                    />
                </div>
                <div class="item-btn">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="复制文案"
                        placement="top"
                    >
                        <span
                            @click="handleCopyText(shortData[item])"
                            style="font-size: 22px"
                            class="iconfont icon-fuzhi"
                        ></span>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="编辑"
                        placement="top"
                    >
                        <span
                            v-hasPermi="['commercial:edit']"
                            @click="handleEditText(item)"
                            style="font-size: 22px; margin: 0 6px"
                            class="iconfont icon-bianji"
                        ></span>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="top"
                    >
                        <span
                            v-hasPermi="['commercial:del']"
                            @click="handleDelText(item)"
                            style="font-size: 22px"
                            class="iconfont icon-shanchu"
                        ></span>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="short-btn">
            <el-button size="mini" class="btn_b" @click="handleAddShortText"
                >添加短语</el-button
            >
        </div>
        <el-dialog
            title="输入快捷短语"
            :visible.sync="dialogVisible"
            width="50%"
            :before-close="handleClose"
            :close-on-click-modal="false"
            :modal="false"
        >
            <el-form :model="form">
                <el-form-item label="快捷短语">
                    <el-input
                        v-model="form.text"
                        autocomplete="off"
                        type="textarea"
                        :rows="2"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSureAddEditText"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { reqAddShortcut, reqGetShortcut } from "@/api/customer";
import { SERVICE_CODE } from "@/constant/globalConst";
import { copyText } from "@/utils/utils.js";
import tableText from "../../tableText";
export default {
    props: {
        userId: {
            type: String,
            default: "",
        },
        changeTextAreValue: {
            type: Function,
        },
    },

    components: { tableText },

    data() {
        return {
            activeName: "first",
            SINGLE_MERCHANT: "first",
            MERCHANT_GROUP: "second",
            tabList: [
                {
                    id: 1,
                    label: "订单号",
                    name: "first",
                },
                {
                    id: 2,
                    label: "快捷语",
                    name: "second",
                },
            ],
            form: {
                text: "",
            },
            dialogVisible: false,
            shortData: {},
            isEdit: false,
            editId: "",
            updateText: false,
        };
    },
    created() {
        this.getText();
    },
    methods: {
        handleClickShortText(value) {
            this.$emit("changeTextAreValue", value);
        },

        handleCopyText(text) {
            copyText(text);
        },

        /**
         * @description 点击添加短语 打开模态框
         */
        handleAddShortText() {
            this.openModal({ isEdit: false });
        },

        /**
         * @description 点击编辑
         */
        handleEditText(id) {
            this.openModal({ id, isEdit: true });
        },

        /**
         * @description 点击删除
         */
        async handleDelText(id) {
            try {
                await this.$modal.confirm("确认删除此短语吗?");
                const textObj = { ...this.shortData };
                delete textObj[id];
                this.unifiedRequestText(textObj, "删除成功");
            } catch (error) {}
        },

        /**
         * @description 打开模态框事件
         */
        openModal({ id, isEdit }) {
            if (isEdit) {
                this.editId = id;
                this.isEdit = true;
                this.form.text = this.shortData[id] ?? "";
            } else {
                this.editId = "";
                this.isEdit = false;
                this.form.text = "";
            }

            this.dialogVisible = true;
        },

        /**
         * @description 点击模态框 确认事件
         */
        handleSureAddEditText() {
            if (this.isEdit) {
                this.editText(this.editId, this.form.text);
            } else {
                this.addText(this.form.text);
            }
        },

        /**
         * @description 请求添加短语
         */
        async addText(text) {
            const id = `id_${this.$tool?.getOnlyId()}`;

            const textObj = {
                ...this.shortData,
            };
            textObj[id] = text;

            this.unifiedRequestText(textObj, "新增成功");
        },

        /**
         * @description 请求编辑短语
         */
        async editText(id, text) {
            this.$tool.isBetaConsole(id, text);
            if (!id && !text) {
                this.$message.error("编辑的id或者文本未获取到,请刷新");
                return;
            }
            const textObj = { ...this.shortData };
            textObj[id] = text;

            this.unifiedRequestText(textObj, "编辑成功");
        },

        /**
         * @description 得到短语
         */
        async getText() {
            const getTextRes = await reqGetShortcut();
            if (getTextRes.code === SERVICE_CODE.SUCCESS) {
                this.shortData = getTextRes.data ?? {};
                this.updateText = true;
            }
        },

        /**
         * @description 统一请求短语，因为新增，编辑，删除 都是一个接口
         */
        async unifiedRequestText(textObj, TipMsg) {
            const addRes = await reqAddShortcut(textObj);
            if (addRes.code === SERVICE_CODE.SUCCESS) {
                this.$message.success(TipMsg);
                this.dialogVisible = false;
                this.getText();
            }
        },

        handleClose(done) {
            done();
        },
    },
};
</script>
<style lang="scss" scoped>
.content-right-shortcut {
    display: flex;
    flex-direction: column;
}
.short-cut-arr {
    width: 100%;
    height: 440px;
    overflow-y: scroll;
}
.short-btn {
    margin-top: 10px;
}
.short-cut-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ccc;
}
.item-text {
    flex: 1;
    cursor: pointer;
}
.item-btn {
    width: 82px;
}
</style>
