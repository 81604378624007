<template>
    <div class="sideButton">
        <span class="red" v-show="newMsg == 1"></span>
        <div class="customerBtn" @click="openCustomer">
            <i class="icon-kefu iconfont"></i>
            <span>小</span><span>程</span><span>序</span> <span>客</span
            ><span>服</span>
        </div>
        <div class="topBtn" @click="scrollToTop">
            <i class="el-icon-arrow-up"></i>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
    name: "sideButtons",
    data() {
        return {};
    },
    computed: {
        ...mapState(["newMsg"]),
    },
    methods: {
        ...mapMutations(["setCustomer", "setNewMsg"]),
        scrollToTop() {
            this.$emit("scrollToTop");
        },
        openCustomer() {
            this.setNewMsg(0);
            this.setCustomer();
        },
    },
};
</script>
<style lang="scss" scoped>
.sideButton {
    position: fixed;
    right: 20px;
    top: 50vh;
    z-index: 990;
    .red {
        display: block;
        position: absolute;
        right: 3px;
        top: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: red;
    }
    .customerBtn {
        cursor: pointer;
        background: #548cfa;
        width: 40px;
        text-align: center;
        margin-bottom: 10px;
        padding: 10px 0;
        > i {
            color: #fff;
            font-size: 30px;
        }
        > span {
            display: block;
            text-align: center;
            width: 100%;
            line-height: 1.5;
            font-size: 16px;
            color: #fff;
        }
    }
    .topBtn {
        background: #548cfa;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > i {
            color: #fff;
            font-size: 30px;
        }
    }
}
.iconfont:hover {
    color: #fff !important;
}
</style>
