<template>
    <div class="mbh-left-fixed-box">
        <div class="mbh-logo">
            <!-- <img src="../../../assets/images/logo2.png" alt="公司logo" /> -->
        </div>
        <div>
            <div class="userInfo" v-loading="$store.state.loading">
                <div class="user-info-base">
                    <!-- <div class="user-info-tenantName">
            {{ $store.state.user.tenantName }}
          </div> -->
                    <el-avatar :size="76" :src="$store.state.user.logo">
                        <img src="../../../assets/images/user.png" />
                    </el-avatar>
                    <div class="name">{{ $store.state.user.nickname }}</div>
                    <div v-if="adminIs" class="detail">
                        <img
                            class="load"
                            v-bind:class="{ rotate: isRotating }"
                            @click="load"
                            src="../../../assets/images/reload.png"
                        />
                        <div class="money" v-if="$store.state.user.balance">
                            余额：{{ $store.state.user.balance | toYuan }}
                        </div>
                        <div class="money" v-else>余额：0</div>
                    </div>
                    <div class="threshold">
                        <span>余额提醒</span>
                        <span>{{
                            $store.state.thresholdInfo.thresholdState
                                ? "开启"
                                : "关闭"
                        }}</span>
                        <el-button
                            size="mini"
                            type="primary"
                            @click="setVal"
                            class="btn_a"
                        >
                            设置
                        </el-button>
                    </div>

                    <div class="btn-box">
                        <el-button
                            size="mini"
                            type="primary"
                            plain
                            @click="detail"
                            class="detail-font btn_b"
                        >
                            明细
                        </el-button>
                        <el-button
                            v-if="adminIs"
                            class="detail-font"
                            size="medium"
                            type="primary"
                            @click="rechargeClick"
                            >充值</el-button
                        >
                    </div>
                </div>
                <!-- <i style="color: #548cfa" class="iconfont icon-bianji edit"></i> -->
            </div>
            <!-- menu -->
            <el-scrollbar class="box-menu">
                <el-menu
                    unique-opened
                    @select="handleOpen"
                    :router="false"
                    :default-active="activeMenu"
                    @close="handleClose"
                    class="mbh-menu-el"
                >
                    <router-link to="/">
                        <el-menu-item index="/Home">
                            <img
                                src="../../../assets/images/menu/shouye.svg"
                                alt=""
                            />
                            <!-- <i class="iconfont icon-shouye-zhihui menu-icon"></i> -->
                            <span slot="title" class="menu-title">首页</span>
                        </el-menu-item>
                    </router-link>
                    <template v-for="item in menuList">
                        <template v-if="item.isOne">
                            <router-link
                                :to="item.children[0].path"
                                :key="item.id"
                            >
                                <el-menu-item :index="item.children[0].path">
                                    <img
                                        :src="
                                            require('@/assets/images/menu/' +
                                                item.meta.icon +
                                                '.svg')
                                        "
                                        alt=""
                                    />
                                    <!-- <i class="iconfont" :class="item.meta.icon"></i> -->
                                    <span slot="title">{{
                                        item.meta.title
                                    }}</span>
                                </el-menu-item>
                            </router-link>
                        </template>
                        <el-submenu :index="item.path" :key="item.id" v-else>
                            <template slot="title">
                                <img
                                    :src="
                                        require('@/assets/images/menu/' +
                                            item.meta.icon +
                                            '.svg')
                                    "
                                    alt=""
                                />
                                <!-- <i class="iconfont" :class="item.meta.icon"></i> -->
                                <span slot="title">{{ item.meta.title }}</span>
                            </template>
                            <el-menu-item-group>
                                <template v-for="items in item.children">
                                    <template v-if="items.isShow">
                                        <router-link
                                            :to="items.path"
                                            :key="items.id"
                                        >
                                            <el-menu-item
                                                :index="items.path"
                                                class="two-menu"
                                                >{{
                                                    items.meta.title
                                                }}</el-menu-item
                                            >
                                        </router-link>
                                    </template>
                                </template>
                            </el-menu-item-group>
                        </el-submenu>
                    </template>
                </el-menu>
            </el-scrollbar>
            <rechargePopup ref="rechargePopup" />
            <setVal ref="setVal" />
        </div>
    </div>
</template>
<script>
import rechargePopup from "./components/rechargePopup.vue";
import setVal from "./components/setVal.vue";
import { userThreshold } from "@/api/common";
export default {
    components: {
        rechargePopup,
        setVal,
    },
    data() {
        return {
            disabled: false,
            isRotating: false,
            menuList: [],
            popupShow: false,
            username: "",
            activeMenu: "/home",
            adminIs: null,

            user: {},
        };
    },
    filters: {
        toYuan(value) {
            return (value / 100).toFixed(2) + "元";
        },
    },
    created() {
        // this.username = JSON.parse(sessionStorage.getItem("userInfo")).userName;
        if (sessionStorage.getItem("routerArr")) {
            this.menuList = JSON.parse(
                sessionStorage.getItem("routerArr") || []
            );
        }
        this.adminIs = JSON.parse(sessionStorage.getItem("userInfo")).adminIs;
    },
    methods: {
        // 设置
        setVal() {
            this.$refs.setVal.show(true);
        },
        load() {
            this.isRotating = true;
            setTimeout(() => {
                this.isRotating = false;
            }, 500);
            this.$store.dispatch("setUser");
            this.$store.dispatch("setThreshold");
        },
        detail() {
            this.$router.push("/recharge/list");
        },
        rechargeClick() {
            this.$refs.rechargePopup.show(true);
        },
        handleOpen(key, keyPath) {
            // this.$tool.isBetaConsole("key", key);
            // this.activeMenu = key;
        },
        handleClose(key, keyPath) {
            // this.$tool.isBetaConsole(key, keyPath);
        },
    },
    watch: {
        $route: {
            handler(newVal) {
                // this.$tool.isBetaConsole("newVal", newVal);
                this.activeMenu = newVal.path;
            },
            immediate: true,
        },
    },
    // computed
};
</script>
<style lang="scss">
@import "../../../assets/css/commonVar.scss";
.rotate {
    transform: rotate(-360deg);
    transition: transform 0.5s;
}
.mbh-left-fixed-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #548cfa;
    .mbh-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: $logo-height;
        background-color: white;
        img {
            height: 31px;
        }
    }

    .userInfo {
        width: 100%;
        overflow: hidden;
        position: relative;
        padding-bottom: 12px;

        .edit {
            position: absolute;
            right: 13px;
            top: 13px;
        }

        .user-info-base {
            position: relative;
            @include flex-column-center;
            width: 100%;
            .user-info-tenantName {
                position: absolute;
                top: 12px;
                color: #fff;
            }

            .el-avatar {
                margin: 30px auto 6px;
                display: block;
                background-color: transparent;
            }

            .name {
                font-size: 18px;
                color: #fff;
                margin-bottom: 3px;
                text-align: center;
            }
            .detail {
                display: none;
                align-items: center;
                // margin-left: 30px;
                margin-bottom: 8px;
                .load {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    cursor: pointer;
                }
                .money {
                    font-size: 14px;
                    color: #fff;
                    margin-right: 8px;
                }
            }
            .threshold {
                color: #fff;
                display: none;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                & > span {
                    padding-right: 7px;
                    line-height: 21px;
                }
                .el-switch {
                    width: 47px;
                    height: 21px;
                }
                .btn_a {
                    width: 47px;
                    height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #3d72db;
                }
            }
            .btn-box {
                display: none;

                .detail-font {
                    width: 75px;
                    height: 33px;
                    background: #72a2ff;
                    border-radius: 16px;
                    color: #fff;
                    font-weight: 400;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                // .cz {
                //   @include flex-row-center;
                //   width: 108px;
                //   height: 28px;
                //   border-radius: 14px;
                //   border: none;
                //   background-color: #72a2ff;
                // }
            }
        }
    }

    .box-menu {
        height: calc(100vh - $logo-height + $scroll-bar-size);
        img {
            width: 24px;
            height: 24px;
            margin-right: 21px;
        }
        .mbh-menu-el {
            width: 199px;
            margin: 0 auto;
            border: none;
            background-color: #548cfa;
            a {
                border-radius: 23px;
            }

            .el-menu-item,
            .el-submenu__title {
                @include menu-item-style;
            }

            .el-menu-item.is-active {
                @include menu-item-active;
            }
            .el-menu-item:hover,
            .el-submenu__title:hover {
                background-color: #3d72db;
                border-radius: 20px;
            }

            .el-menu-item-group,
            .el-menu {
                background-color: #548cfa;
                .el-menu-item-group__title {
                    height: 0px;
                    padding: 0px;
                }
                .el-menu-item {
                    padding: 0 !important;
                    padding-left: 64px !important;
                    color: #fff;
                }
                .is-active {
                    color: $common-font-color;
                }
            }
            .two-menu {
                background: transparent;
            }
        }
    }
}
.el-avatar > img {
    width: 100% !important;
}
</style>
