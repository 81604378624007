import {
    removeEmpty,
    getOnlyId,
    getQuery,
    setToken,
    getToken,
    removeToken,
    setObject,
    setRouter,
    getRouterArr,
    removeRouter,
    setUserInfo,
    getUserInfo,
    removeUserInfo,
    removeVuex,
    clearUserStorage,
    rememberUser,
    getRememberUser,
    fenToYuan,
    yuanToFen,
    formatThousand,
    isBetaConsole,
    formatTime,
    openConsole,
} from '@/utils/tool'
import * as Api from "@/utils/request.js";
let httpRequest = Api.default.httpRequest;

export default {
    httpRequest,
    removeEmpty,
    getOnlyId,
    getQuery,
    setToken,
    getToken,
    removeToken,
    setObject,
    setRouter,
    getRouterArr,
    removeRouter,
    setUserInfo,
    getUserInfo,
    removeUserInfo,
    removeVuex,
    clearUserStorage,
    rememberUser,
    getRememberUser,
    fenToYuan,
    yuanToFen,
    formatThousand,
    isBetaConsole,
    formatTime,
    openConsole,
}
