<template>
    <div v-if="dialogVisible">
        <el-dialog
            :close-on-click-modal="false"
            :title="checkType === 'pass' ? '修改密码' : '修改个人资料'"
            :append-to-body="true"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="beforeClose"
        >
            <!-- 表单 -->
            <el-form
                v-if="checkType === 'pass'"
                label-position="top"
                :model="passForm"
                status-icon
                :rules="rulePass"
                ref="rulePass"
                label-width="100px"
                class="demo-passForm"
            >
                <el-form-item label="旧密码" prop="oldPass">
                    <el-input
                        type="password"
                        v-model="passForm.oldPass"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="pass">
                    <el-input
                        type="password"
                        v-model="passForm.pass"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="password">
                    <el-input
                        type="password"
                        v-model="passForm.password"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form
                v-if="checkType === 'info'"
                label-position="top"
                :model="info"
                status-icon
                :rules="ruleInfo"
                ref="ruleInfo"
                label-width="100px"
                class="demo-passForm"
            >
                <el-form-item label="头像" prop="logo">
                    <uploadImg
                        :url="{ data: info.logo }"
                        @handleImgSuccess="handleImgSuccessToCoverURL"
                    />
                </el-form-item>
                <el-form-item label="用户名" prop="nickname">
                    <el-input
                        v-model="info.nickname"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button
                    class="btn_a"
                    size="mini"
                    type="primary"
                    @click="addSubmit"
                    >修改</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import uploadImg from "../../views/shopManagement/components/uploadImg.vue";
import { checkUserInfo, checkPassword } from "@/api/authority";
import { outLogin } from "@/api/login";
import { clearUserStorage } from "@/utils/tool";
export default {
    components: { uploadImg },
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.passForm.pass) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        var validLogo = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择头像！"));
            } else {
                callback();
            }
        };
        return {
            checkType: "",
            dialogVisible: false,
            passForm: {
                pass: "",
                password: "",
                oldPass: "",
            },
            info: {
                logo: "",
                nickname: "",
            },
            rulePass: {
                pass: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: "blur",
                    },
                ],
                oldPass: [
                    {
                        required: true,
                        message: "请输入旧密码",
                        trigger: "blur",
                    },
                ],
            },
            ruleInfo: {
                logo: [
                    { required: true, validator: validLogo, trigger: "change" },
                ],
                nickname: [
                    { required: true, message: "请填写昵称", trigger: "blur" },
                ],
            },
        };
    },
    methods: {
        handleImgSuccessToCoverURL(data) {
            this.info.logo = data.data;
        },
        beforeClose() {
            this.dialogVisible = false;
        },
        show(type, val) {
            this.checkType = val;
            this.dialogVisible = type;
            if (val === "info") {
                this.info = { ...this.$store.state.user };
                this.$tool.isBetaConsole("store", this.$store.state.user);
            }
        },
        addSubmit() {
            let formName = this.checkType === "pass" ? "rulePass" : "ruleInfo";
            this.$tool.isBetaConsole(this.info, this.$refs[formName]);
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let res;
                    let r;
                    if (this.checkType === "pass") {
                        // 修改密码
                        res = await checkPassword({
                            oldPwd: this.passForm.oldPass,
                            newPwd: this.passForm.password,
                        });
                    } else {
                        // 修改资料
                        r = await checkUserInfo({ ...this.info });
                        if (r.code == 200) {
                            this.$message.success("修改用户资料成功");
                            this.$store.dispatch("setUser");
                        }
                        this.$tool.isBetaConsole(r);
                    }
                    if (res && res.code && res.code === 200) {
                        const data = await outLogin();
                        this.$tool.isBetaConsole(data);
                        if (data.code == 200) {
                            clearUserStorage();
                            this.$router.push("/login");
                        }
                    }
                    this.dialogVisible = false;
                } else {
                    this.$tool.isBetaConsole("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload .avatar {
    border-radius: 50%;
}
</style>
