import { v4 as uuidv4 } from "uuid";
import config from "../settings";
import { CONSOLE_TYPE } from './constant'
import moment, { fn } from 'moment'

/**
 * @description 删除对象中的undefined 或者 '' 值的key
 * @param {*} obj
 */
export const removeEmpty = (obj) => {
  let resultObj = {};
  const allKeys = Object.keys(obj ?? {});
  allKeys.forEach((item) => {
    if (obj?.[item] !== undefined && obj?.[item] !== "") {
      resultObj[item] = obj[item];
    }
  });
  return resultObj;
};

/**
 * @description 获取唯一id
 * @returns {string}
 */
export const getOnlyId = () => {
  return uuidv4().replaceAll('-', '');
};

/**
 * @description 返回路径上面的参数
 */
export function getQuery() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const paramObject = {};
  for (const [key, value] of params) {
    paramObject[key] = value;
  }

  return paramObject;
}

/**
 * @description 字节流转base64
 * @param {*} stream 流
 */
export function byteStreamToUrl(stream) {
  function transformArrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    for (var len = bytes.byteLength, i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  let dataStr = transformArrayBufferToBase64(stream);
  const qrcodeImg = "data:image/png;base64," + dataStr;
  return qrcodeImg;
}

export function setToken(token) {
  sessionStorage.setItem(config.TokenKey, token);
}

export function getToken() {
  return sessionStorage.getItem(config.TokenKey);
}

export function removeToken() {
  sessionStorage.removeItem(config.TokenKey);
}

export function setObject(key, value) {
  let setValueObj = value;

  if (!setValueObj || typeof setValueObj !== "object") {
    setValueObj = [...setValueObj];
  }

  try {
    setValueObj = JSON.stringify(setValueObj);
  } catch (error) {
    setValueObj = "[]";
  }

  window.sessionStorage.setItem(key, setValueObj);
}

export function setRouter(obj) {
  setObject(config.RouterArrKey, obj);
}

export function getRouterArr() {
  return JSON.parse(sessionStorage.getItem(config.RouterArrKey) ?? "[]");
}

export function removeRouter() {
  sessionStorage.removeItem(config.RouterArrKey);
}

export function setUserInfo(obj) {
  setObject(config.UserInfoKey, obj);
}

export function getUserInfo() {
  return JSON.parse(sessionStorage.getItem(config.UserInfoKey) ?? "{}");
}

export function removeUserInfo() {
  sessionStorage.removeItem(config.UserInfoKey);
}

export function removeVuex() {
  sessionStorage.removeItem("vuex");
}

export function clearUserStorage() {
  removeToken();
  removeRouter();
  removeUserInfo();
  removeVuex();
}

export function rememberUser(loginData) {
  const { username, password } = loginData ?? {};
  localStorage.setItem("username", username);
  localStorage.setItem("password", password);
}

export function getRememberUser() {
  const username = localStorage.getItem("username") ?? "";
  const password = localStorage.getItem("password") ?? "";
  return {
    username,
    password,
  };
}

export function formatThousand(str) {
  let str1 = (Number(str)).toFixed(2)
  //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
  if (str1 === "NaN") {
    return '0.00'
  }
  //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
  const intSum = String(str1).substring(0, str1.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ","); //取到整数部分
  const dot = str1.substring(str1.length, str1.indexOf(".")); //取到小数部分搜索
  let ret = intSum + dot;
  return ret
}

/**
 * @description 分转元
 * @param {*} fenStr 传入的分
 * @param {*} config  isFormat:是否需要格式化保留两位小数 1111,1.00 isSymbol:cn 是否需要金钱符号 cn代表￥
 * @returns 
 */
export function fenToYuan(fenStr, config = {}) {

  const { isFormat, isSymbol } = config

  let str = (Number(fenStr) / 100).toFixed(2)

  if (isFormat) {
    if (str === "NaN") {
      return '0.00'
    }

    let ret = formatThousand(str)
    // const intSum = String(str).substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ","); //取到整数部分
    // const dot = str.substring(str.length, str.indexOf(".")); //取到小数部分搜索
    // let ret = intSum + dot;
    switch (isSymbol) {
      case 'cn':
        ret = '￥' + ret
        break;
      default:
        break;
    }
    return ret
  }
  if (str === "NaN") {
    return 0
  }
  return Number(str);
}

export function yuanToFen(yuanStr, config = {}) {

  const { isFormat, isSymbol } = config

  let retNum = String(parseInt((Number(yuanStr) * 100)))
  if (isFormat) {
    if (retNum === "NaN") {
      return "0.00"
    }
    let str = Number(retNum).toFixed(2)
    let ret = formatThousand(str)
    //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
    // const intSum = String(str).substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ","); //取到整数部分
    // const dot = str.substring(str.length, str.indexOf(".")); //取到小数部分搜索
    // let ret = intSum + dot;
    switch (isSymbol) {
      case 'cn':
        ret = '￥' + ret
        break;
      default:
        break;
    }
    return ret
  }
  if (retNum === "NaN") {
    return 0
  }
  return Number(retNum)
}

function consoleObj(data, name) {
  if (!data) {
    data = undefined
  }
  if (typeof data === 'object') {
    console.dir(data);
  } else {
    console.log(`consoleData======>${name}`, data, typeof data)
  }

}

function consolePage(consoleName, consoleData, time) {
  console.group(`\n\n----------开始---------->>${consoleName}<<-----------开始------------\n来源于页面: ${window.location.href}\n打印时间: ${time}\n\n`)
  consoleObj(consoleName, "consoleName")
  consoleObj(consoleData, 'consoleData')
  console.log(`\n\n-----------结束-------->>${consoleName}<<-------------结束---------\n来源于页面: ${window.location.href}\n打印时间: ${time}\n\n`)
  console.groupEnd()
}

function consoleRequest(consoleName, consoleData, time) {
  const { url, method, data, params } = consoleData

  if (consoleName === '请求') {
    console.log(`\n\n---${url}-------统一发起请求参数开始---------->>${consoleName}<<-----------统一发起请求参数开始------------\n来源于请求\n打印时间: ${time}\n\n`)
    console.log(`请求路径名称：${url}`,)
    console.log(`请求方法：${method}`,)
    console.log('请求参数：data', data)
    console.log('请求参数：params', params)
    console.log('requestData===>', consoleData)
    console.log(`\n\n-----------统一发起请求参数结束-------->>${consoleName}<<-------------统一发起请求参数结束---------\n来源于请求\n打印时间: ${time}\n\n`)
  } else {
    console.log(`\n\n----------收到请求开始---------->>${consoleName}<<-----------收到请求开始------------\n来源于请求\n打印时间: ${time}\n\n`)
    console.log(`请求路径名称：${url}`,)
    console.log(`请求方法：${method}`,)
    console.log('请求参数：data', data)
    console.log('请求参数：params', params)
    console.log('requestData===>', consoleData)
    console.log(`\n\n-----------收到请求开始结束-------->>${consoleName}<<-------------收到请求开始结束---------\n来源于请求\n打印时间: ${time}\n\n`)
  }

}

function allConsole(consoleName, consoleData, time) {
  consolePage(consoleName, consoleData, time)
  consoleRequest(consoleName, consoleData, time)
}


export function isBetaConsole(consoleName = '', consoleData = '', type = CONSOLE_TYPE.PAGE, extra = {}) {
  try {
    const openConsole = localStorage.getItem('consoleVariable')
    const openConsoleType = localStorage.getItem('consoleType') ?? CONSOLE_TYPE.PAGE

    if (openConsoleType !== type && openConsoleType !== CONSOLE_TYPE.ALL) {
      return
    }

    const env = process.env.NODE_ENV

    if (env === 'production' && openConsole !== 'open') {
      return
    }

    if ((!openConsole || openConsole === 'close') && env !== "development") {
      return
    }

    if (!consoleName) {
      consoleName = JSON.stringify(consoleName)
    }
    const time = formatTime()
    switch (type) {
      case CONSOLE_TYPE.PAGE:
        consolePage(consoleName, consoleData, time)
        break;
      case CONSOLE_TYPE.REQUEST:
        consoleRequest(consoleName, consoleData, time)
        break;
      case CONSOLE_TYPE.ALL:
        allConsole(consoleName, consoleData, time)
        break;
      default:
        break;
    }
  } catch (error) {
    console.error("console error", error)
  }
}

export function formatTime(date = new Date().getTime()) {
  return moment(date).format(
    "YYYY年MM月DD日 h:mm:ss a"
  )
}

/**
 * @description 打开console
 * request 打开统一请求console  page 打开页面console  all不传 打开全部console
 */
export function openConsole(type = CONSOLE_TYPE.PAGE) {
  localStorage.setItem('consoleVariable', 'open')
  localStorage.setItem('consoleType', type)
  window.location.reload()
}

export async function preloadImageAndGetSize(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onerror = reject;
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };

    img.src = url;
  });
}

export function heightAuto(params) {
  const { imgWidth, imgHeight, fatherWidth, fatherHeight } = params

  const originalImageRatio = Number((imgWidth / imgHeight).toFixed(1))

  let height = Number(fatherWidth / originalImageRatio).toFixed(2)

  height = pxToEm(height)

  return {
    width: '100%',
    height,
  }

}

export function pxToEm(num) {
  const fontSize = 14
  if (!num) {
    return 0
  }
  return (num / fontSize).toFixed(2)
}