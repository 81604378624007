<template>
    <div class="app-main" ref="main">
        <sideButtons @scrollToTop="scrollToTop"/>
        <transition name="fade-transform" mode="out-in">
            <router-view v-if="!$route.meta.link" :key="key" />
            <!-- <keep-alive exclude="createAdvertisement,home">
        <router-view v-if="!$route.meta.link" :key="key" />
      </keep-alive> -->
        </transition>
        <!-- <iframe-toggle /> -->
    </div>
</template>

<script>
import sideButtons from "./side-buttons.vue";
export default {
    name: "AppMain",
    components: { sideButtons },
    computed: {
        cachedViews() {
            return this.$store.state.tagsView.cachedViews;
        },
        key() {
            return this.$route.path;
        },
    },
    methods: {
        scrollToTop() {
            this.$emit('scrollToTop')
            // this.$refs.main.scrollTop = this.$refs.main.scrollHeight;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/commonVar.scss";

.app-main {
    position: relative;
    height: 100%;
    width: 100%;
    background: $app-main-bg-color;
    // overflow: hidden;
}
@media screen and (min-width: 750px) {
    .app-main {
        min-width: $app-main-min-width;
    }
}
.fixed-header + .app-main {
    padding-top: 50px;
}

.hasTagsView {
    .app-main {
        /* 84 = navbar + tags-view = 50 + 34 */
        min-height: calc(100vh - 84px);
    }

    .fixed-header + .app-main {
        padding-top: 84px;
    }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
    .fixed-header {
        padding-right: 6px;
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 3px;
}
</style>
