<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: "App",
    created() {
        let isWs = sessionStorage.getItem("isWS") || this.$store.state.isWS;
        this.$store.commit("setWS", isWs);
    },
};
</script>
