import { Message } from "element-ui";
import QRCode from "qrcode";
import { userDown_ } from "@/api/common"
export function isEmpty(value) {
    switch (typeof value) {
        case 'undefined':
            return true
        case 'string':
            if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true
            break
        case 'boolean':
            if (!value) return true
            break
        case 'number':
            if (value === 0 || isNaN(value)) return true
            break
        case 'object':
            if (value === null || value.length === 0) return true
            for (const i in value) {
                return false
            }
            return true
    }
    return false
}

export function copyText(text) {
    const input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    window.__LIN__?.modal?.msgSuccess?.('复制成功')
}

export function debounce(fn, delay = 1000) {
    let timer = null
    return function () {
        let args = arguments
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
        timer = setTimeout(() => {
            fn.apply(this, args)// this 指向vue
        }, delay)
    }
}

// 链接转换成二维码图片
export function getQRcode(linkUrl) {
    return new Promise((resolve, reject) => {
        QRCode.toDataURL(linkUrl, (err, url) => {
            if (err) reject(err);
            resolve(url);
        });
    });
}


