/** ====自身==== */
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import directive from "./directive"; // 自定义指令
import plugins from "./plugins";
/** ====自身==== */

/** 第三方库 */
// import uuid from "uuid";
import ElementUI from "element-ui";
// import VueAreaLinkage from 'vue-area-linkage';
// import VueClipBoard from 'vue-clipboard2'  // 使用文档 ：https://blog.csdn.net/qq_39081064/article/details/127240836

Vue.directive("loadmore", {
    bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
            if (this.scrollHeight - this.scrollTop <= this.clientHeight) {
                binding.value();
            }
        });
    },
});
Vue.directive("loadmorer", {
    bind(el, binding) {
        const selectWrap = el.querySelector(".el-select-dropdown__wrap");
        selectWrap.addEventListener("scroll", function () {
            if (this.scrollHeight - this.scrollTop <= this.clientHeight) {
                binding.value();
            }
        });
    },
});
/** 第三js方库 */

import moment from "moment";
Vue.prototype.$moment = moment;
moment.locale("zh-CN");
/** =====样式处理===== */
import "./assets/css/common.css"; // 引入公共重置样式
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/font_4269751_olftc2j91za/iconfont.css";
// import "amfe-flexible/index.js"; //  引入rem
import "./assets/css/layout.scss"; // 公共样式
import "./assets/css/transition.scss";
// import 'vue-area-linkage/dist/index.css'; // v2 or higher
/** =====样式处理===== */

Vue.use(ElementUI);
Vue.use(directive);
// Vue.use(VueClipBoard)
Vue.use(plugins);
// Vue.use(VueAreaLinkage)

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
