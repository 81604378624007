import * as Api from "@/utils/request.js";
let httpRequest = Api.default.httpRequest;
// 列表
export function orderList(params) {
  return httpRequest({
    url: `/api/wx_goods_order`,
    params,
    method: "GET",
  });
}
// 订单发货
export function deliver(orderId, logisticsOrderNo) {
  return httpRequest({
    url: `/api/wx_goods_order/deliver/${orderId}/${logisticsOrderNo}`,
    method: "POST",
  });
}
// 订单退款
export function refundOrder(orderId, refundAmount) {
  return httpRequest({
    url: `/api/wx_goods_order/refund/${orderId}/${refundAmount}`,
    method: "PUT",
  });
}
// 修改物流
export function editLogs(orderId, logisticsOrderNo) {
  return httpRequest({
    url: `/api/wx_goods_order/deliver/${orderId}/${logisticsOrderNo}`,
    method: "PUT",
  });
}
// 修改备注
export function remarkOrder(orderId, params) {
  return httpRequest({
    url: `/api/wx_goods_order/remark/${orderId}`,
    params,
    method: "PUT",
  });
}
// 导出发送验证码
export function sendCode() {
  return httpRequest({
    url: `/api/wx_goods_order/sendCode`,
    method: "PUT",
  });
}
// 脱敏手机号解密.
export function desensitized(params) {
  return httpRequest({
    url: `/api/wx_goods_order/desensitized`,
    params,
    method: "GET",
  });
}

// 拼多多订单列表
export function reqPddOrderList(params) {
  return httpRequest({
    url: `/api/pdd_goods_order`,
    params,
    method: "GET",
  });
}