<template>
    <div class="customer_box">
        <div class="customer_header">
            <div class="customer-header-left">
                <!-- <img src="../../assets/images/logo3.png" alt="" /> -->
                <!-- <el-link
                    class="left-des"
                    type="primary"
                    href="https://hvsr4ex73o.feishu.cn/docx/Alv5dVZNgolcPyxrPYxcB8Evn5c"
                    target="_blank"
                    >客服使用说明</el-link
                > -->
            </div>
            <p>{{ miniAppName }}</p>
            <i class="el-icon-close" @click="setCustomer"></i>
        </div>
        <div class="customer_content">
            <!-- 会话列表 -->
            <customerLeft
                :sessionList="session"
                :curUser="curUser"
                :flickerId="flickerId"
                :audioSwitch="audioSwitch"
                @changeMsg="changeMsg"
                @changeAudio="handleChangeAudio"
                @chooseUser="chooseUser"
                @closeUser="closeUser"
            />
            <!-- 聊天界面 -->
            <customerCenter
                :msgList="msgList"
                :userId="userId"
                :appId="appId"
                :openId="openId"
                :curUser="curUser"
                :newMsg="newMsg"
                @changeCustomer="changeCustomer"
                ref="customerCenterRef"
            />
            <!-- 订单界面 -->
            <customerRight
                :userId="userId"
                @changeTextAreValue="changeTextAreValue"
                @sendsMsg="sendsMsg"
            />
            <!-- 音效 -->
            <audio ref="myAudio" src="../../assets/mp3/tip.mp3" hidden></audio>
            <!-- 测试 -->
            <!-- <el-button @click="handleTest">测试按钮</el-button> -->
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import customerLeft from "./customer-left.vue";
import customerCenter from "./customer-center.vue";
import customerRight from "./customer-right.vue";
import { sessionList, getMsg } from "@/api/customer.js";
import config from "@/settings";
import { getToken } from "@/utils/tool";
export default {
    components: {
        customerLeft,
        customerCenter,
        customerRight,
    },
    data() {
        return {
            flickerId: "", //需要闪烁的消息id
            session: [], //会话列表
            curUser: false, //是否选中用户
            msgList: [], //聊天消息列表
            heartCheck: null, //心跳定时器
            socket: null, //ws
            newMsg: {}, //收到的信息
            userId: "",
            appId: "",
            openId: "",
            miniAppName: "",
            timeStamp: "", //最后一条消息的时间戳
            audioSwitch: true,
            STORAGE_KEY_AUDIO: "audioSwitch",
        };
    },
    created() {
        this.getInitAudioSwitch();
        this.sessionList();
        if (this.isWS == 1) {
            this.createdSession();
        }
    },
    destroyed() {
        this.socket.close();
    },
    computed: {
        ...mapState(["customerShow", "isWS"]),
    },
    // watch: {
    //     // 监听聊天弹窗开关 打开在执行一次客服列表接口
    //     customerShow(newValue) {
    //         if (newValue) {
    //             this.sessionList();
    //         }
    //     },
    // },
    methods: {
        ...mapMutations(["setCustomer", "setWS", "setNewMsg"]),

        playSound() {
            this.testAutoPlay().then((res) => {
                if (!res) {
                    this.openComponent();
                    return;
                }
                const audio = this.$refs.myAudio;
                // 开始播放音效
                audio.muted = false; //静音播放
                audio.currentTime = 0; // 重新从头部开始播放
                audio.volume = 1; // 设置音量为最大值
                audio.play();
            });
        },

        getInitAudioSwitch() {
            const initSwitchAudio = window.localStorage.getItem(
                this.STORAGE_KEY_AUDIO
            );
            if (initSwitchAudio && initSwitchAudio === "true") {
                this.audioSwitch = true;
            }
            if (initSwitchAudio && initSwitchAudio === "false") {
                this.audioSwitch = false;
            }
        },

        /**
         * @description 测试是不是可以自动播放并返回结果
         */
        testAutoPlay() {
            let autoplay = false;
            return new Promise((resolve) => {
                let audioPlay = this.$refs.myAudio;
                audioPlay.currentTime = 0; //从头开始播放提示音
                // audioPlay.muted = true; //静音播放
                // play返回的是一个promise
                audioPlay
                    .play()
                    .then(() => {
                        // 支持自动播放
                        autoplay = true;
                    })
                    .catch((err) => {
                        // 不支持自动播放
                        autoplay = false;
                    })
                    .finally(() => {
                        // 告诉调用者结果
                        resolve(autoplay);
                    });
            });
        },

        async openComponent() {
            try {
                const res = await this.$modal.confirm(
                    "检查出打开了客服消息自动播放提示音，请确认是否授权声音权限给系统"
                );
                // 获取音频对象
                const audio = this.$refs.myAudio;
                // 开始播放音效
                audio.currentTime = 0; // 重新从头部开始播放
                audio.volume = 1; // 设置音量为最大值
                audio.play();
            } catch (error) {
                this.audioSwitch = false;
                this.$tool.isBetaConsole("error", error);
            }
        },

        /**
         * @description 切换声音
         */
        handleChangeAudio(val) {
            this.audioSwitch = val;
            window.localStorage.setItem(this.STORAGE_KEY_AUDIO, val);
        },

        changeTextAreValue(value) {
            if (this.userId) {
                this.$refs.customerCenterRef?.changeTextAreValue(value);
                return;
            }
            this.$message.info("请选择用户");
        },

        /**
         * @description 发送信息
         */
        sendsMsg({ msgType = "text", goodsId, productData }) {
            if (!this.userId) {
                this.$message.info("请选择对应发送的用户");
                return;
            }

            if (msgType === "miniprogrampage") {
                const params = {
                    msgType: "miniprogrampage",
                    goodsId,
                    productData,
                };
                this.$refs.customerCenterRef?.sendsToUser(params);
            }
        },

        /**
         * @description 切换用户
         */
        handleTest() {
            if (this.userId) {
                this.$refs.customerCenterRef?.changeTextAreValue("1111111");
                this.$refs.customerCenterRef?.sends();
            }
            this.$tool.isBetaConsole(this.$refs.customerCenterRef);
            // this.chooseUser("1727153725762686978");
        },

        // 切换客服
        changeCustomer() {
            this.curUser = false;
            this.miniAppName = "";
            this.userId = "";
            this.timeStamp = "";
            this.appId = "";
            this.openId = "";
            this.sessionList();
        },
        // 关闭用户
        closeUser(e) {
            //判断关闭的用户是不是当前用户
            if (e == this.userId) {
                this.curUser = false;
                this.miniAppName = "";
                this.userId = "";
                this.timeStamp = "";
                this.appId = "";
                this.openId = "";
            }
        },
        // 选择用户聊天
        chooseUser(e) {
            this.miniAppName = e.miniProgramName;
            this.userId = e.userId;
            this.appId = e.appId;
            this.openId = e.openId;
            this.curUser = true;
            this.getMsgList();
        },
        // 获取最后一条时间戳
        async getMsgList() {
            let params = {
                pageSize: 1,
                userId: this.userId,
            };
            const res = await getMsg(params);
            this.timeStamp = res.data?.[0]?.timeStamp || "";
        },
        // 开关消息
        changeMsg(e) {
            if (e) {
                this.createdSession();
                this.setWS(1);
                this.$message.info("已经打开接受消息");
            } else {
                this.socket.close();
                this.setWS(0);
                this.$message.info("已关闭接受消息");
            }
        },
        // 查询客服列表
        async sessionList() {
            const res = await sessionList();
            if (res.code == 200) {
                this.session = res.data || [];
            }
        },
        changeSort(obj) {
            const index = this.session.findIndex(
                (item) => item.userId == obj.userId
            );

            if (index > -1) {
                this.session.splice(index, 1);
                this.session.unshift(obj);
            }
        },
        // 建立聊天会话
        createdSession() {
            // 创建 WebSocket 对象，传入服务器地址
            this.socket = new WebSocket(
                `${config.baseWS}/${getToken()}?timeStamp=${
                    this.timeStamp
                }&&userId=${this.userId}`
            );
            // 监听 WebSocket 的打开事件
            this.socket.addEventListener("open", (event) => {
                this.$tool.isBetaConsole("打开聊天", event);
                this.heartCheck = setInterval(() => {
                    this.$tool.isBetaConsole("发送心跳", "HeartBeat");
                    this.socket.send("HeartBeat");
                }, 10000); // 发送心跳消息的时间间隔，单位毫秒
            });
            // 监听 WebSocket 接收到消息的事件
            this.socket.addEventListener("message", (event) => {
                let message = JSON.parse(event.data);
                this.$tool.isBetaConsole("响应", message);
                // 如果有新消息，判断是否打开了 音效开关 有的话 就开启一次
                if (this.audioSwitch) {
                    this.playSound();
                }
                if (!this.customerShow) {
                    this.setNewMsg(1);
                }
                // 接收到信息判断是否在当前会话中 不是则添加
                const isNewMsg = this.session.find(
                    (item) => item.userId == message.userId
                );
                // this.$tool.isBetaConsole(isNewMsg.userId, this.userId);
                if (isNewMsg) {
                    if (isNewMsg.userId != this.userId) {
                        isNewMsg.msgFlag = true;
                        this.flickerId = isNewMsg.userId;
                        // this.changeSort(isNewMsg);
                    }
                } else {
                    setTimeout(() => {
                        this.sessionList();
                    }, 1000);
                }
                // 判断收到的信息是否是正在和我聊天的
                const isNowMsg = message.userId == this.userId;
                if (isNowMsg) {
                    this.newMsg = message;
                    this.timeStamp = message.timeStamp;
                }
                // 清楚定时器
                clearInterval(this.heartCheck);
                this.heartCheck = setInterval(() => {
                    this.socket.send("HeartBeat");
                }, 15000);
            });
            // 监听 WebSocket 关闭事件
            this.socket.addEventListener("close", (event) => {
                this.$tool.isBetaConsole("close", event);
                clearInterval(this.heartCheck);
            });
            // 监听 WebSocket 发生错误的事件
            this.socket.addEventListener("error", (event) => {
                this.createdSession();
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.customer_box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 1023px;
    height: 596px;
    background: #f9f9f9;
    box-shadow: 0px 2 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    background: #fff;
    overflow: hidden;
    .customer_content {
        display: flex;
        height: 544px;
    }
    .customer-header-left {
        display: flex;
        align-items: center;
        justify-content: center;

        .left-des {
            cursor: pointer;
            margin-left: 5px;
            font-size: 12px;
        }
    }
    .customer_header {
        height: 52px;
        background: #5c45bc;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-icon-close {
            margin-right: 20px;
            color: #fff;
            font-size: 30px;
            cursor: pointer;
            //   width: 116px;
            //   text-align: right;
        }
        img {
            width: 116px;
            height: 26px;
            margin-left: 20px;
        }
        p {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
            font-size: 14px;
            color: #ffffff;
        }
    }
}
</style>
