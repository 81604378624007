import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";

/* Layout */
import Layout from "@/layout";
import { getRouterArr, getToken, getUserInfo } from "@/utils/tool";

Vue.use(VueRouter);

// 公共路由
let constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect"),
      },
    ],
  },
  {
    path: "/404",
    component: (resolve) => require(["@/views/features/404"], resolve),
    hidden: true,
  },
  {
    path: "/401",
    component: (resolve) => require(["@/views/features/401"], resolve),
    hidden: true,
  },
  {
    path: "",
    component: Layout,
    redirect: "Home",
    children: [
      {
        path: "Home",
        name: "home",
        component: () => import("@/views/Home"),
      },
      {
        path: "/Home/createAdvertisement",
        name: "createAdvertisement",
        component: () => import("@/views/createAdvertisement/index"),
      },
    ],
  },
  {
    path: "/login",
    hidden: true,
    component: () => import("@/views/login"),
  },
  {
    path: "/adUrl",
    hidden: true,
    component: () => import("@/views/adUrl"),
  },
  // 测试页面
  {
    path: "/testPage",
    hidden: true,
    component: () => import("@/views/testPage"),
  },
  {
    path: "/shopManagement/addShop",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/shopManagement/addShop",
        component: () => import("@/views/shopManagement/addShop"),
      },
      {
        path: "/shopManagement/orderDetail",
        component: () => import("@/views/shopManagement/orderDetail"),
      }, {
        path: "/shopManagement/saleDetail",
        component: () => import("@/views/shopManagement/saleDetail"),
      },
      {
        path: "/shopManagement/freight",
        component: () => import("@/views/shopManagement/freight"),
      },
    ],
  },
  {
    path: "/authorityManagement/addRole",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/authorityManagement/addRole",
        component: () => import("@/views/authorityManagement/addRole"),
      },
    ],
  },
  {
    path: "/recharge/list",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/recharge/list",
        component: () => import("@/views/recharge/list"),
      },
    ],
  },
  {
    path: "/inform",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/inform",
        component: () => import("@/views/inform/index"),
      }, {
        path: "/inform/details",
        component: () => import("@/views/inform/details"),
      },
    ],
  },
  {
    path: "/sendMsg",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/sendMsg",
        component: () => import("@/views/sendMsg"),
      },
    ],
  }, {
    path: "/upSuccess",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/upSuccess",
        component: () => import("@/views/upSuccess"),
      },
    ],
  },
];
// 修改原型对象中的push函数
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  routes: constantRoutes,
  mode: "history",
});

const dynamicRoutes = JSON.parse(sessionStorage.getItem("routerArr"));
if (dynamicRoutes && dynamicRoutes.length > 0) {
  dynamicRoutes.map((v) => {
    v.component = Layout;
    for (let i in v.children) {
      v.children[i].component = () => import(`@/views${v.children[i].path}`);
    }
    router.addRoute(v);
  });
}
router.beforeEach((to, from, next) => {
  // this.$tool.isBetaConsole(from);
  // 登录和权限验证
  const token = getToken();
  const toPath = to.path;
  // 未登录
  if (!token && toPath != "/login") {
    Message.warning("请先登录", "error");
    return next({ path: "/login" });
  }
  // 防止重复登录
  if (token && toPath == "/login") {
    Message.warning("请勿重复登录", "error");
    return next({ path: from.path ? from.path : "/" });
  }

  // 校验是否有当前页面 如果没有就去到404页面
  if (token) {
    const allRouterPathArr = router.getRoutes().map((item) => item.path);
    const isHasPath = allRouterPathArr.some((item) => item === toPath);
    if (!isHasPath) {
      next("/404");
    }
  }

  next();
  // 设置页面标题
  document.title = to.meta.title ? to.meta.title : "东方智能投放系统";
});

export default router;
