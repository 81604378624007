import * as Api from "@/utils/request.js";
let httpRequest = Api.default.httpRequest;

import { LIST_TYPE } from '@/views/LaunchManagement/const'

const apiPrefix = (listType) => {
  return `/api${listType === LIST_TYPE.PDD ? '/pdd' : ''}`
}

// 投诉列表
export function getComplaint(params) {
  return httpRequest({
    url: `/api/complaint`,
    params,
    method: "GET",
  });
}
// 投诉列表处理
export function getComplaintDeal(params, complaintId) {
  return httpRequest({
    url: `/api/complaint/handle/${complaintId}`,
    params,
    method: "PUT",
  });
}
// 投诉详情
export function getComplaintInfo(params) {
  return httpRequest({
    url: `/api/complaint/info`,
    params,
    method: "GET",
  });
}
// 实时流量
export function getFlowData(params) {
  return httpRequest({
    url: `/api/index/flow`,
    params,
    method: "GET",
  });
}
// 转化明细列表
export function getUseDateilChange(params, listType) {
  return httpRequest({
    url: `${apiPrefix(listType)}/change`,
    params,
    method: "GET",
  });
}
// 转化明细列表  新增明细
// export function getChange(data){
// 	return httpRequest({
//         url: `/api/change`,
//         data,
//         method: "POST",
//     });
// }

// 获取授权链接
export function getOceanengineAudit(params) {
  return httpRequest({
    url: `/api/oceanengine/audit`,
    params,
    method: "GET",
  });
}
// 授权回调
export function getOceanengineToken(params) {
  return httpRequest({
    url: `/api/oceanengine/getToken`,
    params,
    method: "GET",
  });
}
// 授权列表
export function getOceanengineList(params) {
  return httpRequest({
    url: `/api/oceanengine`,
    params,
    method: "GET",
  });
}

// 工作台今日数据查询;
export function businessData(params) {
  return httpRequest({
    url: `/api/index`,
    params,
    method: "GET",
  });
}

// 手动回传
export function getChangeReport(changeId, listType) {
  return httpRequest({
    url: `${apiPrefix(listType)}/change/report/${changeId}`,
    method: "GET",
  });
}
// 开启/关闭自动控评
export function getChangeOceanengine(params, advertiserId) {
  return httpRequest({
    url: `/api/oceanengine/${advertiserId}`,
    params,
    method: "GET",
  });
}
// 授权删除
export function getDelOceanengine(params) {
  return httpRequest({
    url: `/api/oceanengine`,
    params,
    method: "DELETE",
  });
}

// 充值明细
export function getTop_upRecord(params) {
  return httpRequest({
    url: `/api/top_up/record`,
    params,
    method: "GET",
  });
}
// 分页查询今日排行榜
export function getTodayRank(params) {
  return httpRequest({
    url: `/api/index/operation`,
    params,
    method: "GET",
  });
}
// 商品销售额排行榜
export function getTodayGoods(params) {
  return httpRequest({
    url: `/api/index/sales`,
    params,
    method: "GET",
  });
}
// 授权列表二级数据
export function getOceanengineChildren(id) {
  return httpRequest({
    url: `/api/oceanengine/second/${id}`,
    method: "GET",
  });
}
// 发送记录
export function sendMessage(params) {
  return httpRequest({
    url: `/api/sms`,
    params,
    method: "GET",
  });
}
// 用户下拉框
export function userDown_() {
  return httpRequest({
    url: `/api/user/down`,
    method: "POST",
  });
}
// 发送记录
export function userInfo(params) {
  return httpRequest({
    url: `/api/user/info`,
    params,
    method: "GET",
  });
}

// 租户阈值设置
export function userThreshold(state, threshold) {
  return httpRequest({
    url: `/api/user/threshold/${state}/${threshold}`,
    method: "PUT",
  });
}
// 获取租户阈值
export function getThresholdInfo(params) {
  return httpRequest({
    url: `/api/user/threshold`,
    params,
    method: "GET",
  });
}
// 获取运费模版
export function getFreightInfo(params) {
  return httpRequest({
    url: `/api/freight`,
    params,
    method: "GET",
  });
}
// 获取运费模版节点数据
export function getFreightChildInfo(freightId) {
  return httpRequest({
    url: `/api/freight/${freightId}`,
    method: "GET",
  });
}
// 删除运费模版
export function getDelFreight(freightId) {
  return httpRequest({
    url: `/api/freight/${freightId}`,
    method: "DELETE",
  });
}
// 开启/关闭模版
export function getFreightChange(freightId, state) {
  return httpRequest({
    url: `/api/freight/${state}/${freightId}`,
    method: "PUT",
  });
}
// 小程序投诉列表
export function getComplaintMini_program(params) {
  return httpRequest({
    url: `/api/complaint/wx/mini_program`,
    params,
    method: "GET",
  });
}
// 通知列表
export function getNotice_list(params) {
  return httpRequest({
    url: `/api/notice/list`,
    params,
    method: "GET",
  });
}
// 标记为已读
export function noticeRead(id) {
  return httpRequest({
    url: `/api/notice/read/${id}`,
    method: "POST",
  });
}