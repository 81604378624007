<template>
    <div class="content_left">
        <ul>
            <li
                v-for="(item, i) in sessionList"
                :class="{
                    cur: cur == item.userId,
                    breathing: item.msgFlag,
                }"
                @click="changeChat(i, item)"
                :key="item.userId"
            >
                <el-badge :is-dot="true" :hidden="!item.msgFlag">
                    <el-avatar :size="20" :src="item.avatar">
                        <img src="../../assets/images/avatar.png" alt="" />
                    </el-avatar>
                </el-badge>
                <span>{{ item.nickname || "匿名用户" }}</span>
                <i
                    class="el-icon-close"
                    @click.stop="closeChat(i, item.userId)"
                ></i>
            </li>
        </ul>
        <div class="switch">
            <div class="switch-item">
                接收消息<el-switch
                    @change="change"
                    v-model="value"
                    active-color="#4AE3CA"
                >
                </el-switch>
            </div>

            <div class="switch-item">
                打开音效<el-switch
                    @change="changeAudio"
                    v-model="audioSwitchVal"
                    active-color="#4AE3CA"
                >
                </el-switch>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { delSession, readMsg } from "@/api/customer.js";
export default {
    props: {
        sessionList: {
            type: Array,
            default: [],
        },
        // 选中数据
        curUser: {
            type: Boolean,
            default: false,
        },
        audioSwitch: {
            type: Boolean,
            default: false,
        },
        handleChangeAudio: {
            type: Function,
        },
        flickerId: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapState(["isWS"]),
    },
    created() {
        if (this.isWS == 1) {
            this.value = true;
        } else {
            this.value = false;
        }
        this.audioSwitchVal = this.audioSwitch;
    },
    data() {
        return {
            breath: {
                animation: "breathing 2s ease-in-out 3",
            }, //呼吸样式
            value: true,
            cur: null,
            audioSwitchVal: false,
        };
    },
    watch: {
        curUser(newValue) {
            if (!newValue) {
                this.cur = null;
            }
        },
    },
    methods: {
        // 已读消息
        async setRead(id) {
            const res = await readMsg(id);
        },
        // 选择聊天对象
        changeChat(e, item) {
            this.cur = item.userId;
            this.$emit("chooseUser", item);
            item.msgFlag = false;
            this.setRead(item.userId);
        },
        // 接受消息开关
        change(e) {
            this.$emit("changeMsg", e);
        },
        changeAudio(e) {
            this.$emit("changeAudio", e);
        },
        // 关闭聊天
        async closeChat(index, id) {
            const res = await delSession(id);
            if (res.code == 200) {
                this.sessionList.splice(index, 1);
                this.$emit("closeUser", id);
            } else {
                this.$message.error(res.message);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
// animation: breathing 2s ease-in-out 3;
@keyframes breathing {
    0% {
        opacity: 1;
        background-color: rgba(255, 114, 0, 0.7);
    }

    50% {
        opacity: 0.7;
        background-color: rgba(255, 115, 0, 0.4);
    }

    100% {
        opacity: 1;
        background-color: rgba(255, 114, 0, 0.7);
    }
}
.content_left {
    position: relative;
    width: 142px;
    height: 544px;
    background: #fff;
    ul {
        height: 470px;
        width: 100%;
        overflow: auto;
        li {
            height: 40px;
            display: flex;
            cursor: pointer;
            align-items: center;
            .el-icon-close {
                color: #6f7070;
                font-size: 15px;
            }
            .el-avatar {
                // width: 20px;
                // height: 20px;
                // border-radius: 50%;
                margin-left: 10px;
                margin-right: 4px;
            }
            span {
                display: block;
                font-size: 14px;
                color: #6f7070;
                width: 83px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .breathing {
            animation: breathing 2s ease-in-out 3;
            background-color: rgba(255, 114, 0, 0.7);
        }
        .cur {
            background: rgb(225, 225, 225);
        }
    }
    .switch {
        width: 100%;
        position: absolute;
        bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .switch-item {
            margin-bottom: 10px;
        }
        .el-switch {
            margin-left: 8px;
        }
    }
}
</style>
