<template>
    <div>
        <el-dialog
            title="充值"
            :close-on-click-modal="false"
            :append-to-body="true"
            :destroy-on-close="true"
            :visible.sync="dialogVisible"
            width="788px"
            :before-close="handleClose"
        >
            <div class="box">
                <div class="lt">
                    <el-form
                        label-position="top"
                        :rules="rules"
                        ref="ruleForm"
                        label-width="80px"
                        :model="form"
                    >
                        <el-form-item label="充值金额：" prop="money">
                            <input
                                style="padding: 0 20px"
                                v-model="form.money"
                                placeholder="输入充值金额"
                                onkeyup="value=value.replace(/^\D*(\d*(?:\d{0,0})?).*$/g,'$1')"
                            />
                        </el-form-item>
                        <el-form-item label="支付方式" prop="topUpEnum">
                            <div class="choose">
                                <el-radio-group v-model="form.topUpEnum">
                                    <el-radio
                                        style="margin-bottom: 30px"
                                        label="WX"
                                    >
                                        <img
                                            class="logo"
                                            src="@/assets/images/weixin.png"
                                            alt=""
                                        />
                                        微信</el-radio
                                    ><br />
                                    <el-radio label="ALI_PAY">
                                        <img
                                            class="logo"
                                            src="@/assets/images/zfb.png"
                                            alt=""
                                        />支付宝</el-radio
                                    >
                                </el-radio-group>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div style="text-align: right; width: 740px">
                                <el-button
                                    style="width: 98px"
                                    class="btn_a"
                                    size="mini"
                                    @click="getUrl('ruleForm')"
                                >
                                    确认
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="imgUrl" class="rt">
                    <h6 style="margin-left: 24px">二维码</h6>
                    <img :src="imgUrl" class="qrCode" alt="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { top_up, top_upWxNotify } from "@/api/login";
import { getQRcode } from "@/utils/utils";
import axios from "axios";
export default {
    data() {
        return {
            dialogVisible: false,
            imgUrl: "",
            form: {
                money: "",
                topUpEnum: "WX",
            },
            rules: {
                money: [
                    { required: true, message: "请填写金额" },
                    {
                        // trigger: "change"
                        validator: (rule, value, callback) => {
                            let reg = /^[1-9]\d*$/;
                            this.$tool.isBetaConsole(reg.test(value));
                            if (reg.test(value)) {
                                callback();
                            } else {
                                callback(new Error("请输入正整数"));
                            }
                        },
                    },
                ],
                topUpEnum: [
                    {
                        required: true,
                        message: "请选择支付方式",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    created() {},
    watch: {
        form: {
            handler(newVal, oldVal) {},
            deep: true,
        },
    },
    destroyed() {},
    methods: {
        sure() {},
        getUrl(formName) {
            if (this.form.topUpEnum === "ALI_PAY") {
                this.$message.warning("暂未接入支付宝 功能开发中~");
                return;
            }
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let parmas = {
                        money: this.$tool.yuanToFen(this.form.money),
                        topUpEnum: this.form.topUpEnum,
                    };
                    let res = await top_up(parmas);
                    if (res.code === 200 && res.data) {
                        if (res.data.codeUrl) {
                            if (this.form.topUpEnum === "WX") {
                                getQRcode(res.data.codeUrl).then((r) => {
                                    this.imgUrl = r;
                                });
                            } else {
                                window.location.href = r?.data?.codeUrl;
                            }
                        }
                        let data = await top_upWxNotify(res?.data?.outTradeNo);
                        if (data.code === 200 && data.data) {
                            this.dialogVisible = false;
                            this.$store.dispatch("setUser");
                            this.$router.push(
                                `/upSuccess?money=${this.form.money}`
                            );
                        }
                    } else {
                        this.imgUrl = "";
                    }
                } else {
                    this.$tool.isBetaConsole("error submit!!");
                    return false;
                }
            });
        },
        show(show) {
            this.dialogVisible = show;
        },
        handleClose(done) {
            this.form = {
                money: "",
                topUpEnum: "WX",
            };
            this.imgUrl = "";
            done();
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep.el-dialog__body {
    padding-bottom: 0 !important;
}
.el-radio__label {
    img {
        padding-right: 10px;
    }
}
.box {
    display: flex;
    h6 {
        font-size: 14px;
        color: #000000;
    }
    .rt {
        margin-left: -240px;
        margin-top: 36px;
        img {
            width: 227px;
            height: 227px;
        }
    }
    .lt {
        input {
            width: 427px;
            height: 45px;
            background: #f9f9f9;
            border-radius: 23px;
            margin-top: 11px;
            margin-bottom: 28px;
            font-size: 16px;
        }
        .choose {
            margin-top: 30px;
            .el-radio {
                line-height: 43px;
            }
        }
    }
    .logo {
        width: 43px;
        height: 43px;
    }
}
</style>
