<template>
    <div class="detail">
        <div class="back">
            <i class="el-icon-arrow-left" @click="back"></i>
        </div>
        <div class="content">
            <div class="status">
                订单状态：<span>{{
                    ORDER_STATUS[`${detailData.orderState}`] ?? "未知"
                }}</span>
            </div>
            <div class="info">
                <i class="iconfont icon-dizhi01"></i>
                <div class="info-rt">
                    <div class="top">
                        {{ detailData.name }}
                        <span>{{ detailData.phone }}</span>
                    </div>
                    <p>
                        {{ detailData.integrityAddress }}
                    </p>
                </div>
            </div>
            <div class="shop">
                <img :src="detailData.coverUrl" alt="" />
                <div class="shop-rt">
                    <p>{{ detailData.goodsName }}</p>
                    <div>商品规格；共{{ detailData.num }}件</div>
                </div>
            </div>
            <ul class="list">
                <li>
                    <p>支付方式</p>
                    <span v-if="detailData.clientType === 'WX'">微信支付</span>
                    <span v-else-if="detailData.clientType === 'ZFB'"
                        >支付宝支付</span
                    >
                    <span v-else>未知</span>
                </li>
                <li>
                    <p>商品总额</p>
                    <span class="red">{{
                        $tool?.fenToYuan(detailData.totalPrice, {
                            isFormat: true,
                            isSymbol: "cn",
                        })
                    }}</span>
                </li>
                <li>
                    <p>优惠</p>
                    <span>{{
                        $tool?.fenToYuan(detailData.discountAmount, {
                            isFormat: true,
                            isSymbol: "cn",
                        })
                    }}</span>
                </li>
                <li>
                    <p>运费</p>
                    <span v-if="detailData.postage">{{
                        $tool.fenToYuan(detailData.postage, {
                            isFormat: true,
                            isSymbol: "cn",
                        })
                    }}</span>
                    <span v-else>包邮</span>
                </li>
                <li>
                    <p>支付总额</p>
                    <span class="red">{{
                        $tool?.fenToYuan(detailData.payAmount, {
                            isFormat: true,
                            isSymbol: "cn",
                        })
                    }}</span>
                </li>
                <li>
                    <p>订单号</p>
                    <span>{{ detailData.orderId }}</span>
                </li>
                <li>
                    <p>创建时间</p>
                    <span>{{ detailData.createTime }}</span>
                </li>
            </ul>
        </div>
        <div class="bottom">
            <span
                v-hasPermi="['goods:order:up_shipping_addr']"
                v-if="
                    detailData.orderState === 'NO_PAID' ||
                    detailData.orderState === 'WAIT_SHOP'
                "
                @click="toOrder"
                >修改地址</span
            >
            <span
                v-if="detailData.orderState == 'WAIT_SHOP'"
                v-hasPermi="['goods:order:deliver']"
                @click="toOrder"
                >去发货</span
            >
            <span
                v-if="
                    detailData.orderState == 'WAIT_SHOP' ||
                    detailData.orderState == 'WAIT_SIGN' ||
                    detailData.orderState == 'SUCCESS'
                "
                v-hasPermi="['goods:order:refund']"
                @click="toOrder"
            >
                去退款
            </span>
            <span
                v-hasPermi="['goods:order:up_deliver']"
                @click="toOrder"
                v-if="detailData.orderState == 'WAIT_SIGN'"
                >修改物流</span
            >
        </div>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
import { wx_goods_orderInfo, wx_goods_orderLogistic } from "@/api/shop";
import { ORDER_STATUS, allowRefundArr } from "@/utils/constant";
export default {
    props: {
        orderId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ORDER_STATUS,
            detailData: {},
        };
    },
    watch: {
        orderId: {
            handler(val) {
                if (val) {
                    this.getDetail();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapMutations(["setCustomer"]),
        back() {
            this.$emit("toBack");
        },
        toOrder() {
            this.setCustomer();
            this.$router.push(
                `/shopManagement/order?orderId=${this.detailData?.orderId}`
            );
        },
        getDetail() {
            wx_goods_orderInfo(this.orderId)
                .then((res) => {
                    this.detailData = res.data || {};
                })
                .catch((e) => {
                    this.$tool.isBetaConsole(e);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.detail {
    width: 375px;
    .bottom {
        position: fixed;
        bottom: 0;
        width: 375px;
        height: 48px;
        box-sizing: border-box;
        padding-right: 20px;
        background: #f9f9f9;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
            display: block;
            width: 90px;
            height: 34px;
            background: #ffffff;
            border-radius: 20px;
            margin-left: 10px;
            text-align: center;
            line-height: 34px;
            font-size: 14px;
            color: #000;
            cursor: pointer;
        }
    }
}
.content {
    padding: 0 10px;
    .list {
        padding: 10px;
        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 14px;
            color: #000000;
            span {
                color: #7d7d7d;
            }
            .red {
                color: #ff1818;
            }
        }
    }
    .shop {
        padding: 10px;
        display: flex;
        > img {
            width: 64px;
            height: 68px;
        }
        .shop-rt {
            width: 210px;
            margin-left: 8px;
            p {
                font-size: 16px;
                color: #000000;
            }
            div {
                font-size: 16px;
                color: #b3b3b3;
                margin-top: 6px;
            }
        }
    }
    .status {
        font-size: 14px;
        margin-bottom: 10px;
        span {
            color: #ff1818;
        }
    }
    .info {
        display: flex;
        margin-bottom: 20px;
        > i {
            font-size: 23px;
            color: #ff723b;
        }
        .info-rt {
            margin-left: 10px;
            width: 301px;
            .top {
                color: #000000;
                font-size: 16px;
                span {
                    color: #7f7f7f;
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
            p {
                font-size: 14px;
                color: #7f7f7f;
                line-height: 20px;
            }
        }
    }
}
.back {
    height: 40px;
    display: flex;
    align-items: center;
    i {
        color: #6f7070;
        font-size: 20px;
        margin-left: 9px;
        cursor: pointer;
    }
}
</style>
