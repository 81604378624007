<template>
    <div class="dropdownMessage">
        <div class="mbh-left-fixed-box">
            <el-dropdown ref="dropdownMessager">
                <div class="cd">
                    <img
                        width="23"
                        height="23"
                        src="../../assets/images/cd.png"
                        alt=""
                    />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!-- menu -->
                    <el-scrollbar style="width: 199px" class="box-menu">
                        <el-menu
                            unique-opened
                            @select="handleOpen"
                            :router="false"
                            :default-active="activeMenu"
                            @close="handleClose"
                            class="mbh-menu-el"
                        >
                            <router-link to="/">
                                <el-menu-item index="/Home">
                                    <span slot="title" class="menu-title"
                                        >首页</span
                                    >
                                </el-menu-item>
                            </router-link>
                            <template v-for="item in menuList">
                                <template v-if="item.isOne">
                                    <router-link
                                        :to="item.children[0].path"
                                        :key="item.id"
                                    >
                                        <el-menu-item
                                            :index="item.children[0].path"
                                        >
                                            <span slot="title">{{
                                                item.meta.title
                                            }}</span>
                                        </el-menu-item>
                                    </router-link>
                                </template>
                                <el-submenu
                                    :index="item.path"
                                    :key="item.id"
                                    v-else
                                >
                                    <template slot="title">
                                        <span slot="title">{{
                                            item.meta.title
                                        }}</span>
                                    </template>
                                    <el-menu-item-group>
                                        <template
                                            v-for="items in item.children"
                                        >
                                            <template v-if="items.isShow">
                                                <router-link
                                                    :to="items.path"
                                                    :key="items.id"
                                                >
                                                    <el-menu-item
                                                        :index="items.path"
                                                        class="two-menu"
                                                        >{{
                                                            items.meta.title
                                                        }}</el-menu-item
                                                    >
                                                </router-link>
                                            </template>
                                        </template>
                                    </el-menu-item-group>
                                </el-submenu>
                            </template>
                        </el-menu>
                    </el-scrollbar>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isRotating: false,
            menuList: [],
            popupShow: false,
            username: "",
            activeMenu: "/home",
            adminIs: null,

            user: {},
        };
    },
    filters: {
        toYuan(value) {
            return (value / 100).toFixed(2) + "元";
        },
    },
    created() {
        // this.username = JSON.parse(sessionStorage.getItem("userInfo")).userName;
        if (sessionStorage.getItem("routerArr")) {
            this.menuList = JSON.parse(
                sessionStorage.getItem("routerArr") || []
            );
        }

        this.adminIs = JSON.parse(sessionStorage.getItem("userInfo")).adminIs;
    },
    methods: {
        load() {
            this.isRotating = true;
            setTimeout(() => {
                this.isRotating = false;
            }, 500);
            this.$store.dispatch("setUser");
        },
        detail() {
            this.$router.push("/recharge/list");
        },
        rechargeClick() {
            this.$refs.rechargePopup.show(true);
        },
        handleOpen(key, keyPath) {
            // this.$tool.isBetaConsole("key", key);
            // this.activeMenu = key;
        },
        handleClose(key, keyPath) {
            // this.$tool.isBetaConsole(key, keyPath);
        },
    },
    watch: {
        $route: {
            handler(newVal) {
                // this.$tool.isBetaConsole("newVal", newVal);
                this.activeMenu = newVal.path;
                if (this.$refs.dropdownMessager)
                    this.$refs.dropdownMessager.hide();
            },
            immediate: true,
        },
    },
    // computed
};
</script>
<style lang="scss">
@import "../../assets/css/commonVar.scss";
.dropdownMessage {
    flex: 1;
    padding-left: 10px;
    .mbh-left-fixed-box {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #fff;
        @media (max-width: 750px) {
            .cd {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
        @media (min-width: 750px) {
            .cd {
                display: none;
            }
        }

        .box-menu {
            // height: calc(100vh - $logo-height + $scroll-bar-size - 260px);
            height: 100%;
            img {
                width: 24px;
                height: 24px;
                margin-right: 21px;
            }
            .mbh-menu-el {
                //   width: 199px;
                margin: 0 auto;
                border: none;
                background-color: #548cfa;
                a {
                    border-radius: 23px;
                }

                .el-menu-item,
                .el-submenu__title {
                    @include menu-item-style;
                }

                .el-menu-item.is-active {
                    @include menu-item-active;
                }
                .el-menu-item:hover,
                .el-submenu__title:hover {
                    background-color: #3d72db;
                    border-radius: 20px;
                }

                .el-menu-item-group,
                .el-menu {
                    background-color: #548cfa;
                    .el-menu-item-group__title {
                        height: 0px;
                        padding: 0px;
                    }
                    .el-menu-item {
                        padding: 0 !important;
                        padding-left: 64px !important;
                        color: #fff;
                    }
                    .is-active {
                        color: $common-font-color;
                    }
                }
                .two-menu {
                    background: transparent;
                }
            }
        }
    }
    .el-avatar > img {
        width: 100% !important;
    }
}
</style>
