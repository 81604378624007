import modal from './modal'
import download from './download'
import tool from './tool'
import store from '../store/index'

const mountMap = {
    modal,
    download,
    tool,
    store
}

export default {
    install(Vue) {
        // 模态框对象
        Vue.prototype.$modal = mountMap.modal
        // 下载文件
        Vue.prototype.$download = mountMap.download
        // 通用函数
        Vue.prototype.$tool = mountMap.tool
    }
}

// 挂载在window
window.__LIN__ = mountMap
