<template>
    <div>
        <el-dialog
            title="阈值设置"
            :close-on-click-modal="false"
            :append-to-body="true"
            :destroy-on-close="true"
            :visible.sync="dialogVisible"
            width="610px"
            :before-close="handleClose"
        >
            <el-form
                label-position="top"
                :rules="rules"
                ref="ruleForm"
                label-width="80px"
                :model="ruleForm"
            >
                <el-form-item label="阈值：" prop="threshold">
                    <div class="txt">开启后余额达到阈值将短信提醒</div>
                    <div class="box">
                        <input
                            class="ipt"
                            style="padding: 0 20px"
                            v-model="ruleForm.threshold"
                            placeholder="输入阈值"
                            onkeyup="value=value.replace(/^\D*(\d*(?:\d{0,0})?).*$/g,'$1')"
                        />
                        <span>是否开启</span>
                        <el-switch v-model="ruleForm.state"> </el-switch>
                    </div>
                </el-form-item>

                <el-form-item>
                    <div style="text-align: right">
                        <el-button
                            style="width: 98px"
                            class="btn_a"
                            size="mini"
                            @click="sure('ruleForm')"
                        >
                            确认
                        </el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { userThreshold } from "@/api/common";
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                threshold: "",
                state: false,
            },
            rules: {
                threshold: [
                    { required: true, message: "请填写金额" },
                    {
                        // trigger: "change"
                        validator: (rule, value, callback) => {
                            let reg = /^[1-9]\d*$/;
                            this.$tool.isBetaConsole(reg.test(value));
                            if (reg.test(value)) {
                                callback();
                            } else {
                                callback(new Error("请输入正整数"));
                            }
                        },
                    },
                ],
            },
        };
    },
    created() {},
    methods: {
        sure(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.$tool.isBetaConsole(this.ruleForm);
                    let res = await userThreshold(
                        this.ruleForm.state,
                        this.ruleForm.threshold * 100
                    );
                    if (res.code === 200) {
                        this.dialogVisible = false;
                        this.$store.dispatch("setThreshold");
                    }
                } else {
                    this.$tool.isBetaConsole("error submit!!");
                    return false;
                }
            });
        },
        show(show) {
            this.dialogVisible = show;
            if (show) {
                if (JSON.parse(sessionStorage.getItem("vuex")).thresholdInfo) {
                    this.ruleForm.threshold =
                        JSON.parse(sessionStorage.getItem("vuex")).thresholdInfo
                            .threshold / 100;
                    this.ruleForm.state = JSON.parse(
                        sessionStorage.getItem("vuex")
                    ).thresholdInfo.thresholdState;
                }
            }
        },
        handleClose(done) {
            done();
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form--label-top .el-form-item__label {
    padding: 0;
}
.txt {
    color: #a7a7a7;
    margin-top: -9px;
    margin-left: 6px;
}
.box {
    & > span {
        margin-right: 12px;
    }
    .ipt {
        width: 182px;
        height: 40px;
        background-color: #f9f9f9;
        border-radius: 20px;
        margin-right: 12px;
    }
}
</style>
