<template>
    <div
        class="content_center"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.7)"
    >
        <emoji v-show="emojiShow" @emoji="emoji"></emoji>
        <el-empty description="暂无聊天" v-if="!curUser"></el-empty>
        <template v-else>
            <!-- 切换客服 -->
            <div class="switch-customer">
                <el-dropdown trigger="click" @command="changeCustomer">
                    <span class="el-dropdown-link">
                        切换客服
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="item in custom"
                            :command="item"
                            :key="item.nickname"
                        >
                            <b :class="item.onlineIs ? 'kong' : 'mang'"></b>
                            {{ item.nickname }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div
                class="msg-list"
                ref="scrollDiv"
                @scroll="getScrollUser"
                @click.stop="emojiShow = false"
            >
                <p class="loading" @click="loadingList" v-show="isHistoryList">
                    加载更多历史消息
                </p>
                <!-- 聊天记录 -->

                <ul v-for="item in msgList" :key="item.id">
                    <li class="ME item" v-if="item.type">
                        <div class="user-info">
                            <span
                                >{{ item.nickname }} {{ item.createTime }}</span
                            >
                            <div
                                class="msg-content"
                                v-if="item.msgType == 'text'"
                                v-html="parseText(item.content)"
                            ></div>
                            <div
                                class="msg-content"
                                v-else-if="item.msgType == 'miniprogrampage'"
                            >
                                <goodItem
                                    type="card"
                                    :cardData="item.content"
                                />
                            </div>
                            <div class="msg-content" v-else>
                                <img
                                    @click="getBigImage(item.img)"
                                    v-if="item.img"
                                    class="msg-img"
                                    :src="item.img"
                                />
                                <img
                                    @click="getImage(item)"
                                    v-else
                                    class="msg-img"
                                    src="../../assets/images/image.png"
                                />
                            </div>
                        </div>
                        <el-avatar :size="30" :src="item.avatar">
                            <img
                                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                            />
                        </el-avatar>
                    </li>
                    <li class="YOU item" v-else>
                        <el-avatar :size="30" :src="item.avatar">
                            <img
                                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                            />
                        </el-avatar>
                        <div class="user-info">
                            <span
                                >{{ item.nickname || "匿名用户" }}
                                {{ item.createTime }}</span
                            >
                            <div
                                class="msg-content"
                                v-if="item.msgType == 'text'"
                                v-html="parseText(item.content)"
                            ></div>
                            <div
                                class="msg-content"
                                v-if="item.msgType == 'image'"
                            >
                                <img
                                    @click="getBigImage(item.img)"
                                    v-if="item.img"
                                    class="msg-img"
                                    :src="item.img"
                                />
                                <img
                                    @click="getImage(item)"
                                    v-else
                                    class="msg-img"
                                    src="../../assets/images/image.png"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 底部消息发送框 -->
            <div class="send-box">
                <div class="button-list">
                    <i
                        class="iconfont icon-xiaolian"
                        @click="emojiShow = !emojiShow"
                    ></i>
                    <el-upload
                        ref="upload"
                        class="upload"
                        :action="action"
                        :headers="headers"
                        :show-file-list="false"
                        :limit="1"
                        :on-change="handleChange"
                    >
                        <i class="iconfont icon-tupian"></i>
                    </el-upload>
                </div>
                <!-- <div contenteditable="true" class="textarea"></div> -->
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea"
                    class="textarea"
                    @keyup.enter.native="sends"
                >
                </el-input>
                <button class="sends" @click="sends">发送</button>
            </div>
        </template>
        <!-- 图片弹窗 -->
        <el-image-viewer
            v-if="imgViewerVisible"
            :on-close="closeImg"
            :url-list="imgList"
        />
    </div>
</template>
<script>
import emojiParser from "./components/emoji";
import emoji from "./components/emoji.vue";
import goodItem from "@/component/goodItem";
import { isEmpty } from "@/utils/utils.js";
import { getToken, getOnlyId } from "@/utils/tool.js";
import config from "@/settings";
import { mapState } from "vuex";
import {
    customList,
    getMsg,
    getTempMedia,
    customCut,
    sendMsg,
} from "@/api/customer.js";
let currentScrollHeight = 0;
export default {
    components: {
        "el-image-viewer": () =>
            import("element-ui/packages/image/src/image-viewer"),
        emoji,
        goodItem,
    },
    computed: {
        ...mapState(["isWS"]),
    },
    props: {
        openId: {
            type: String,
            default: "",
        },
        appId: {
            type: String,
            default: "",
        },
        userId: {
            type: String,
            default: "",
        },
        curUser: {
            type: Boolean,
            default: false,
        },
        newMsg: {
            type: Object,
            default: {},
        },
    },
    watch: {
        // 监听聊天弹窗开关 打开在执行一次客服列表接口
        userId(newValue) {
            if (newValue) {
                this.msgList = [];
                this.timeStamp = null;
                this.getMsgList(1);
            }
        },
        appId(newValue) {
            this.action = `${config.baseURL}/api/custom/uploadTempMedia/${newValue}`;
        },
        curUser(newValue) {
            if (newValue) {
                setTimeout(() => {
                    this.scrollToBottom();
                }, 100);
            }
        },
        newMsg(newValue) {
            // this.$tool.isBetaConsole("newMsg", newValue);

            this.$tool.isBetaConsole("newMsg", newValue);
            this.msgList.push(newValue);
            setTimeout(() => {
                this.scrollToBottom();
            }, 100);
        },
    },
    data() {
        return {
            emojiShow: false, //表情弹窗
            imgViewerVisible: false, //是否显示图片弹窗
            imgList: [], //弹窗图片
            pageNum: 1,
            pageSize: 10,
            timeStamp: null,
            custom: [], //客服列表
            action: "",
            headers: {
                Token: getToken(),
            },
            isHistoryList: true, //是否有历史消息
            loading: false, //加载框
            textarea: "", //消息输入框
            msgList: [], //聊天记录
            imgSrc: "", //客服发送的图片
            emojiParser,
        };
    },
    mounted() {
        this.scrollToBottom();
        this.getCustom();
    },
    methods: {
        changeTextAreValue(value) {
            this.textarea = `${this.textarea}${value}`;
        },
        // 关闭图片查看
        closeImg() {
            this.imgViewerVisible = false;
        },
        // 表情相关
        emoji(e) {
            this.emojiShow = false;
            this.textarea = `${this.textarea}${e}`;
        },
        parseText(text) {
            if (!text) return;
            text = text.replace(/\n/g, "<br/>");
            let html = emojiParser(text).replace(
                /<img src/g,
                '<img data-class="iconBox" src'
            );
            html = html.replace(
                "https://oss.mbhad.com/20231205/923c9c9625c7432a86cc1014640a7e42.png",
                "https://oss.mbhad.com/20231205/42115a6b2a7f494da0201582913083c3.png"
            );
            return html;
        },
        // 查看图片
        async getImage(item) {
            this.loading = true;
            let params = {
                appId: item.appId,
                mediaId: item.mediaId,
            };
            const res = await getTempMedia(params);
            this.loading = false;
            if (res.code == 200) {
                item.img = res.data;
            }
        },
        // 查看图片 大图
        getBigImage(item) {
            this.imgList = [];
            this.imgList.push(item);
            this.imgViewerVisible = true;
        },
        // 加载更多
        loadingList() {
            currentScrollHeight = this.$refs.scrollDiv.scrollHeight;
            this.timeStamp = this.msgList[0]?.timeStamp;
            this.pageNum++;
            this.getMsgList(2);
        },
        // 获取聊天记录
        async getMsgList(e) {
            if (!this.curUser) return;
            let params = {
                // pageNum: this.pageNum,
                pageSize: this.pageSize,
                userId: this.userId,
                timeStamp: this.timeStamp,
            };
            this.loading = true;
            const res = await getMsg(params);
            this.loading = false;

            if (res.code == 200) {
                if (res.data && res.data.length > 0) {
                    this.isHistoryList = true;
                    this.msgList = [...res.data, ...this.msgList];
                    if (e == 2) {
                        this.$nextTick(() => {
                            this.$refs.scrollDiv.scrollTop =
                                this.$refs.scrollDiv.scrollHeight -
                                currentScrollHeight;
                        });
                    }
                    if (e == 1) {
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 100);
                    }
                } else {
                    this.isHistoryList = false;
                }
            }
        },
        // 获取客服列表
        async getCustom() {
            const res = await customList();
            if (res.code == 200) {
                this.custom = res.data;
            }
        },
        // 切换客服
        async changeCustomer(e) {
            const res = await customCut(this.userId, e.userId);
            if (res.code == 200) {
                this.$message.success(`已切换给客服:${e.nickname}`);
                this.$emit("changeCustomer");
            }
        },
        // 信息发送接口
        async sendsToUser({
            msgType = "text",
            mediaId,
            text,
            img,
            goodsId,
            productData,
        }) {
            let MyMsg = {
                type: true,
                id: getOnlyId(),
                avatar: this.$store.state.user.logo || "",
                nickname: this.$store.state.user.nickname || "客服",
                msgType: msgType,
                createTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
                productData,
            };
            this.$tool.isBetaConsole("MyMsg", MyMsg);
            let params = {
                appId: this.appId,
                avatar: this.$store.state.user.logo || "",
                nickname: this.$store.state.user.nickname || "客服",
                msgType: msgType,
                toUser: this.openId,
                userId: this.userId,
                text: {
                    content: "",
                },
                image: {
                    mediaId: "",
                },
            };
            if (msgType == "image") {
                params.image.mediaId = mediaId;
                MyMsg.img = img;
            } else {
                params.text.content = text;
                MyMsg.content = text;
            }

            if (msgType === "miniprogrampage") {
                params.goodsMsg = {
                    ...productData,
                };
            }

            // return this.$tool.isBetaConsole(params, MyMsg);
            const res = await sendMsg(params);

            this.loading = false;
            if (res.code == 200) {
                if (msgType === "miniprogrampage") {
                    MyMsg.content = res.data;
                    this.$message.success("发送卡片成功");
                }
                this.msgList.push(MyMsg);
                setTimeout(() => {
                    this.scrollToBottom();
                }, 200);
            } else {
                this.$message.error(`失败`);
            }
        },
        // 发送消息
        async sends() {
            if (isEmpty(this.textarea)) return;
            this.sendsToUser({
                msgType: "text",
                mediaId: "",
                text: this.textarea,
            });
            // this.sendsToUser("text", "", this.textarea);
            this.textarea = "";
        },

        // 发送图片
        handleChange(file) {
            this.$tool.isBetaConsole(file);
            this.loading = true;
            if (file.status == "success") {
                if (file.response.code == 200) {
                    let mediaId = file.response?.data?.mediaId;
                    let params = {
                        appId: this.appId,
                        mediaId: mediaId,
                    };
                    getTempMedia(params).then((res) => {
                        this.$refs.upload.clearFiles();
                        if (res.code == 200) {
                            this.sendsToUser({
                                msgType: "image",
                                mediaId,
                                text: "",
                                img: res.data,
                            });
                            // this.sendsToUser("image", mediaId, "", res.data);
                        } else {
                            this.loading = false;
                        }
                    });
                } else {
                    this.loading = false;
                    this.$refs.upload.clearFiles();
                    this.$message.error(file.response.message || `失败`);
                }
            }
        },
        // 滚动条到底部
        scrollToBottom() {
            if (!this.curUser) return;
            this.$refs.scrollDiv.scrollTo({
                top: this.$refs.scrollDiv.scrollHeight,
                behavior: "smooth",
            });
        },
        // 下拉加载历史记录
        getScrollUser(e) {
            if (e.target.scrollTop === 0 && this.msgList.length > 0) {
                this.loadingList();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.kong {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #2ae956;
}
.mang {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #dbdbdb;
}
.content_center {
    position: relative;
    height: 544px;
    background: #f2f6fc;
    width: 505px;
    .loading {
        text-align: center;
        font-size: 12px;
        color: #548cfa;
        cursor: pointer;
    }
    .switch-customer {
        position: absolute;
        right: 6px;
        bottom: 146px;
        z-index: 1;
        cursor: pointer;

        .el-dropdown-link {
            font-size: 14px;
            color: #548cfa;
            border-radius: 12px;
            border: 1px solid #548cfa;
            background: #f2f6fc;
            width: 108px;
            display: block;
            height: 24px;
            line-height: 24px;
            text-align: center;
        }
    }
    .send-box {
        height: 139px;
        width: 100%;
        background: #ffffff;
        border-top: 1px solid #dbdbdb;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        box-sizing: border-box;
        .textarea {
            width: 100%;
            height: 60px;
        }
        .sends {
            cursor: pointer;
            width: 60px;
            height: 30px;
            background: #5c45bc;
            border-radius: 6px;
            font-size: 14px;
            color: #ffffff;
            float: right;
            margin-right: 10px;
        }
        .button-list {
            display: flex;
            align-items: center;
            margin-left: 17px;
            margin-top: 9px;
            height: 28px;
            i {
                color: #a7a9ae;
                font-size: 18px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .msg-list {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px 40px;
        height: 403px;
        overflow: auto;

        .item {
            display: flex;
            align-items: flex-start;
            margin-top: 20px;
            .el-avatar {
                margin-top: 9px;
            }
            .user-info {
                max-width: 400px;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                > span {
                    color: #6f7070;
                    display: block;
                    margin-bottom: 4px;
                }
                .msg-content {
                    max-width: 288px;
                    padding: 6px 10px;
                    border: 1px solid #dbdbdb;
                    background-color: #fff;
                    color: #000;
                }
                .msg-img {
                    width: 100px;
                    cursor: pointer;
                }
            }

            &.YOU {
                justify-content: flex-start;
                .user-info {
                    margin-left: 6px;
                    align-items: flex-start;
                }
            }
            &.ME {
                justify-content: flex-end;
                .user-info {
                    margin-right: 6px;
                    align-items: flex-end;
                }
                .msg-content {
                    text-align: left;
                }
            }
        }
    }
}
::v-deep .el-textarea__inner {
    resize: none;
    border: none;
}
::v-deep .el-loading-spinner {
    font-size: 40px;
}
</style>
