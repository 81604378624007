import * as Api from "@/utils/request.js";
let httpRequest = Api.default.httpRequest;
// 验证码
export function captcha(data) {
    return httpRequest({
        url: `/api/auth/captcha`,
        data,
        method: "POST",
    });
}
// 注销
export function outLogin(data) {
    return httpRequest({
        url: `/api/auth/logout`,
        data,
        method: "POST",
    });
}
// 登录
export function login(data) {
    return httpRequest({
        url: `/api/auth/login`,
        data,
        method: "POST",
    });
}
// 获取菜单
// 充值
export function top_up(params) {
    return httpRequest({
        url: `/api/top_up`,
        params,
        method: "GET",
    });
}

// 微信充值回调
export function top_upWxNotify(outTradeNo) {
    return httpRequest({
        url: `/api/top_up/wx_result/${outTradeNo}`,
        method: "GET",
    });
}
