import store from "@/store";
export default {
  inserted(el, binding, vnode) {
    // this.$tool.isBetaConsole(el, binding, vnode);
    const { value } = binding;
    let permissions = store.state.permissions || [];
    let all_permission = "*:*:*";
    // 如果是超级管理员设置所有权限
    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value;
      const hasPermissions = permissions.some((permission) => {
        // return permissionFlag.includes(permission);
        return all_permission === permission || permissionFlag.includes(permission);
      });
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`请设置操作权限标签值`);
    }
  },
};
// 使用：v-hasPermi="['sys:list:add']"
